import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { AuthProvider } from "react-oidc-context";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GlobalFooter } from "../common/components/GlobalFooter";
import { NavBar } from "../common/components/NavBar";
import { ScrollToTop } from "../common/components/ScrollToTop";
import { Splash } from "../features/splash/Splash";
import { AuthSecured } from "../common/AuthSecured";
import { Logout } from "../common/Logout";
import { Loading } from "../common/Loading";
import { Dashboard } from "../features/dashboard/Dashboard";
import { Packages } from "../features/packages/Packages";
import { Mail } from "../features/mail/Mail";
import { AUTH_URL, CLIENT_ID, ROLES } from "../config/constants";
import { AuthCallbackDisplay } from "../common/AuthCallbackDisplay";
import { USPSGlobalFooter } from "../common/components/USPSGlobalFooter";
import { NewGlobalHeader } from "../common/components/NewGlobalHeader";

const NavBarContentWrapper = styled.div``;

const FlexWrapper = styled(NavBarContentWrapper)`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 101px);
  justify-content: space-between;
`;

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/authentication/callback") {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  const authConfig = {
    authority: `${AUTH_URL}`,
    client_id: CLIENT_ID,
    response_type: "code",
    scope: "openid informed-delivery",
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
    loadUserInfo: true,
  };

  return (
    <div>
      <AuthProvider
        {...authConfig}
        onSigninCallback={(user) => {
          window.location.href = user.state?.redirectUrl || "/";
        }}
      >
        <ScrollToTop>
          <FlexWrapper>
            <Routes>
              <Route
                element={
                  <>
                    {location.pathname === "/" ? <NewGlobalHeader /> : <NavBar />}
                    <NavBarContentWrapper>
                      <Outlet />
                    </NavBarContentWrapper>
                  </>
                }
              >
                <Route path="/" element={<Splash />} />
                <Route
                  path="/dashboard"
                  element={
                    <AuthSecured requiredRole={ROLES.IDBUS_USER}>
                      <Dashboard />
                    </AuthSecured>
                  }
                />
                <Route
                  path="/packages"
                  element={
                    <AuthSecured requiredRole={ROLES.IDBUS_USER}>
                      <Packages />
                    </AuthSecured>
                  }
                />

                <Route
                  path="/mail"
                  element={
                    <AuthSecured requiredRole={ROLES.IDBUS_USER}>
                      <Mail />
                    </AuthSecured>
                  }
                />
                <Route
                  exact
                  path="/logout"
                  element={
                    <Logout>
                      <Loading />
                    </Logout>
                  }
                />
                <Route path="/authentication/callback" element={<AuthCallbackDisplay />} />
              </Route>
            </Routes>
            {location.pathname === "/" ? <USPSGlobalFooter /> : <GlobalFooter />}
          </FlexWrapper>
        </ScrollToTop>
      </AuthProvider>
    </div>
  );
};

export default App;
