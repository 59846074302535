import { Theme, UspsLogo, CloseIcon, Button } from "@informed-innovations/components";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { CenteredPageLayout } from "./CenteredPageLayout";
import { HamburgerIcon } from "./icons/HamburgerIcon.js";
import { getFirstName, hasRole } from "../../services/apiHelpers";
import { ROLES } from "../../config/constants";
import { useAuth } from "react-oidc-context";
import PropTypes from "prop-types";

const Curtain = styled.div`
  background: ${Theme.colors.gray[200]};
  position: fixed;
  top: 78px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 150;
`;

const CurtainDoubleHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 53px;
  background: ${Theme.colors.blue};
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${Theme.colors.red};

  span {
    border-right: 2px solid white;
    color: white;
    font-size: 18px;
    text-align: center;
  }
`;

const CurtainLogoutLink = styled(Link)`
  padding: 0 !important;
  height: 53px;
  background: ${Theme.colors.blue};
  justify-content: center;
  align-items: center;
  span {
    border-right: none;
  }
`;

const CurtainLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurtainLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  color: black;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  background: ${Theme.colors.gray[200]};
  border-bottom: 1px solid ${Theme.colors.blue};
  &:hover {
    background: ${Theme.colors.gray[400]};
  }
  svg {
    transform: rotate(-90deg);
  }
  ${(props) =>
    props.$active &&
    css`
      span {
        padding-bottom: 2px;
        border-bottom: 4px solid ${Theme.colors.red};
      }
    `}
`;

const BrandLink = styled(Link)``;

const VerticalLine = styled.div`
  width: 1px;
  background-color: ${Theme.colors.blue};
  height: 28px;
`;

const Brand = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    width: 100%;
    a {
      justify-content: center;
    }
    ${(props) =>
      props.center
        ? css`
            justify-content: center;
          `
        : css`
            justify-content: space-between;
            &::after {
              content: "";
              flex-grow: 1;
              flex-basis: 25px;
            }
          `}
  }
`;

const Title = styled.h3`
  padding: 0;
  margin: 0;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  ${(props) =>
    props.active &&
    css`
      span {
        padding-bottom: 2px;
        border-bottom: 4px solid ${Theme.colors.red};
      }
    `}
`;

const LogoLink = styled(Link)``;

const CenterContent = styled(CenteredPageLayout)`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  @media only screen and (max-width: 530px) {
    justify-content: center;
  }
`;

const Desktop = styled.div`
  display: none;
  padding: 10px;
  @media only screen and (min-width: 961px) {
    display: block;
  }
`;

const Responsive = styled.div`
  display: none;
  @media only screen and (max-width: 960px) {
    display: block;
  }
`;

const Container = styled.div`
  position: fixed;
  background: white;
  z-index: 15;
  left: 0;
  right: 0;
  top: 0;
  margin: 0;
  overflow: hidden;
  border-bottom: 1px solid #dfdfdf;

  a {
    display: block;
    color: ${Theme.colors.blue};
    text-align: center;
    text-decoration: none;
    padding: 14px 16px;

    @media only screen and (max-width: 530px) {
      padding: 14px 10px;
    }
  }
  ${Responsive} {
    a {
      display: flex;
      text-align: left;
      padding: 0 10px;
    }
  }
  width: 100%;
`;

const MobileHeader = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 1s cubic-bezier(0.83, 0.02, 0.07, 0.98);
`;

const MobileHeaderInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.9em;
  padding-top: 1.2em;
`;

const HamburgerButton = styled(Button)`
  display: flex;
  padding: 0;
  min-width: 0;
  align-items: center;
  flex: 1 0 25px;
  visibility: ${(props) => (props.authed ? "visible" : "hidden")};
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 3px solid #52addb;
  }
  svg {
    width: 25px;
    height: 25px;
  }
`;

const SubNavContainer = styled.span`
  display: block;
  border-bottom: ${(props) => (props.active ? `4px solid ${Theme.colors.red}` : "4px solid transparent")};

  &:hover {
    border-bottom: 4px solid ${Theme.colors.red};
  }
`;

const SubNavTitle = styled.h5`
  margin-top: 15px;
  margin-bottom: 10px;
`;

const NavLinkStyle = css`
  position: relative;
  text-align: center;
  align-self: center;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: ${Theme.colors.blue};
  border-bottom: 4px solid transparent;

  &:not(:last-child) {
    &::after {
      content: " ";
      border-right: 1px solid ${Theme.colors.gray[600]};
      transform: rotate(20deg);
      position: absolute;
      right: -8px;
      top: 40%;
      height: 30%;
    }
  }
`;

const SubNavExternalLink = styled.a`
  ${NavLinkStyle}
`;
const SubNavLink = styled(Link)`
  ${NavLinkStyle}
`;

const SubNav = ({ active, children, ...props }) => {
  if (props.href) {
    return (
      <SubNavExternalLink {...props}>
        <SubNavContainer active={active}>
          <SubNavTitle>{children}</SubNavTitle>
        </SubNavContainer>
      </SubNavExternalLink>
    );
  }

  return (
    <SubNavLink {...props}>
      <SubNavContainer active={active}>
        <SubNavTitle>{children}</SubNavTitle>
      </SubNavContainer>
    </SubNavLink>
  );
};

export const NavBar = React.forwardRef(({ ...props }, ref) => {
  const [curtainOpen, setCurtainOpen] = useState(false);
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    setCurtainOpen(false);
  }, [location]);
  return (
    <Container {...props} ref={ref}>
      <Desktop>
        <CenterContent>
          <Brand>
            <LogoLink to="/">
              <UspsLogo />
            </LogoLink>
            <VerticalLine />
            <BrandLink to="/">
              <Title>Informed Delivery Business Dashboard</Title>
            </BrandLink>
          </Brand>
          <Links>
            {auth.isAuthenticated && (
              <>
                {hasRole(ROLES.IDBUS_USER) && (
                  <>
                    <SubNav active={location.pathname === "/dashboard"} to="/dashboard">
                      Home
                    </SubNav>
                    <SubNav active={location.pathname === "/packages"} to="/packages">
                      Packages
                    </SubNav>
                    <SubNav active={location.pathname === "/mail"} to="/mail">
                      Mail
                    </SubNav>
                  </>
                )}
                <SubNav to="/logout">Logout</SubNav>
              </>
            )}
          </Links>
        </CenterContent>
      </Desktop>
      <Responsive>
        <MobileHeader>
          <MobileHeaderInnerContent>
            <Brand>
              <HamburgerButton
                text
                title="Menu"
                onClick={() => {
                  setCurtainOpen(!curtainOpen);
                }}
                authed={!!auth.isAuthenticated}
              >
                {curtainOpen ? <CloseIcon /> : <HamburgerIcon />}
              </HamburgerButton>
              <Link to="/">
                <UspsLogo />
              </Link>
            </Brand>
          </MobileHeaderInnerContent>
        </MobileHeader>
        {auth.isAuthenticated && curtainOpen && (
          <Curtain>
            <CurtainDoubleHeader>
              <span>Hi, {getFirstName()}</span>
              <CurtainLogoutLink to="/logout">
                <span>Log out</span>
              </CurtainLogoutLink>
            </CurtainDoubleHeader>
            {hasRole(ROLES.IDBUS_USER) && (
              <CurtainLinks>
                <CurtainLink to="/dashboard" active={location.pathname === "/dashboard"}>
                  <span>Home</span>
                </CurtainLink>
                <CurtainLink to="/packages" active={location.pathname === "/packages"}>
                  <span>Packages</span>
                </CurtainLink>
                <CurtainLink to="/mail" active={location.pathname === "/mail"}>
                  <span>Mail</span>
                </CurtainLink>
              </CurtainLinks>
            )}
          </Curtain>
        )}
      </Responsive>
    </Container>
  );
});

NavBar.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.element,
};
