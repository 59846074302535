import { Button, Theme } from "@informed-innovations/components";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FacilityIcon } from "../../common/components/icons/FacilityIcon";
import { DeliveredIcon } from "../../common/components/icons/DeliveredIcon";
import { ShippingLabelCreatedIcon } from "../../common/components/icons/ShippingLabelCreatedIcon";
import { OutForDeliveryIcon } from "../../common/components/icons/OutForDeliveryIcon";
import { ArriveDepartPostOfficeIcon } from "../../common/components/icons/ArriveDepartPostOfficeIcon";
import PropTypes from "prop-types";

const Container = styled.div`
  margin-top: 78px;
  height: 100vh;

  @media screen and (max-width: 900px) {
    height: 100%;
  }
`;

const PackageType = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 10px;
`;

const Details = styled.div`
  display: block;
  padding: 20px;
  padding-top: 0;
`;

const Detail = styled.div`
  padding: 10px 0;
  p {
    margin-bottom: 0;
  }
`;

const StatusColor = styled.span`
  color: ${(props) => (props.attentionNeeded ? Theme.colors.red : Theme.colors.blue)};
`;

const DetailHeader = styled.div`
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: bold;
`;

const StatusWrapper = styled.div`
  display: flex;
`;

const RideAlong = styled.div`
  padding: 20px 0;
  img {
    max-width: 100%;
  }
`;

const HistoryPointContainer = styled.div`
  display: flex;
`;

const ScanHistoryDate = styled.div`
  width: 120px;
`;

const ScanHistoryLocationIcon = styled.div`
  border: 4px solid ${Theme.colors.white};
  border-radius: 100px;
  margin: 0 auto;
  height: 45px;
  width: 45px;
  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.colors.green};
      border-color: ${Theme.colors.white};
    `}
  ${(props) =>
    props.attentionNeeded &&
    css`
      border-color: ${Theme.colors.white};
    `}
        ${(props) =>
    props.attentionNeeded &&
    props.active &&
    css`
      background-color: ${Theme.colors.red};
    `}
    ${(props) =>
    props.notDelivered &&
    css`
      background-color: ${Theme.colors.white};
      border-color: ${Theme.colors.gray[400]};
    `}
    svg {
    margin-top: 6px;
  }
`;

const ScanHistoryDetail = styled.div`
  background-color: ${Theme.colors.white};
  border-radius: 10px;
  width: 100%;
  height: 100px;
  z-index: 0;
  display: flex;
  position: relative;
  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.colors.green};
      margin-left: -30px;
      padding-left: 15px;
      color: ${Theme.colors.white};
    `}
  ${(props) =>
    props.attentionNeeded &&
    props.active &&
    css`
      background-color: ${Theme.colors.red};
      div {
        margin: auto 0;
        margin-left: 8px;
      }
    `}
        ${(props) =>
    props.notDelivered &&
    css`
      background-color: transparent;
      p {
        color: ${Theme.colors.gray[600]};
      }
    `}
    div {
    flex-direction: column;
    padding-left: 15px;
    margin: auto 0;
  }
  p {
    margin-bottom: 0;
  }
`;

const ProgressBarWrapper = styled.div`
  z-index: 1;
  text-align: center;
  display: block;
  position: relative;
  ${(props) =>
    props.active &&
    css`
      left: -3px;
    `}
`;
const ProgressStep = styled.div`
  margin: 0 20px;
  background-color: ${Theme.colors.green};
  position: relative;
  width: 14px;
  height: 82px;
  ${(props) =>
    props.active &&
    css`
      margin: 0 auto;
    `}
  ${(props) =>
    props.active &&
    props.attentionNeeded &&
    css`
      margin: 0 12px;
      background-color: ${Theme.colors.red};
    `}
    ${(props) =>
    props.point &&
    css`
      ::after {
        content: "";
        width: 0.62em;
        height: 0.62em;
        transform: rotate(45deg);
        background: ${Theme.colors.green};
        position: absolute;
        z-index: 0;
        top: -5px;
        left: 2px;
      }
    `}
    ${(props) =>
    props.endOfSteps &&
    css`
      ::before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 30px;
        background: ${Theme.colors.green};
        position: absolute;
        z-index: 0;
        bottom: -6px;
        left: 0;
      }
    `}
        ${(props) =>
    props.notDelivered &&
    css`
      background-color: ${Theme.colors.gray[400]};
    `}
`;

const CurtainHeader = styled.div`
  display: none;
  height: 53px;
  background: ${Theme.colors.blue};
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${Theme.colors.red};

  span {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;
const ScanHistoryTableWrapper = styled.div`
  position: relative;
  h3 {
    padding: 10px 0;
  }
  button {
    position: absolute;
    bottom: -16px;
    left: 120px;
    color: ${Theme.colors.indigo};
    text-decoration: underline;
    min-width: 0px;
    max-width: 130px;
    width: 100%;
    &:focus {
      outline: none;
      border: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  padding: 30px;
  text-align: center;
  button {
    max-width: 100%;
    width: 100%;
  }
`;

const renderIcon = (i, data) => {
  if (data.status.includes("Facility")) {
    return <FacilityIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Shipping Label")) {
    return <ShippingLabelCreatedIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Out for delivery")) {
    return <OutForDeliveryIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Delivered")) {
    return <DeliveredIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  return <ArriveDepartPostOfficeIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
};

const ScanHistoryTable = ({ scanHistory, attentionNeeded, viewAmount }) => {
  return scanHistory.map((scan, i) => {
    if (i >= viewAmount) return <div key={`${scan}-${i}`}></div>;

    if (!scanHistory[i].status.includes("Delivered") && i === 0) {
      return (
        <React.Fragment key={`${scan}-${i}`}>
          <HistoryPointContainer>
            <ScanHistoryDate>
              <div>
                <p style={{ marginBottom: "0" }}>
                  <b></b>
                </p>
                <p> </p>
              </div>
            </ScanHistoryDate>
            <ProgressBarWrapper>
              <ScanHistoryLocationIcon notDelivered={true}>
                <DeliveredIcon fill={`${Theme.colors.gray[400]}`} />
              </ScanHistoryLocationIcon>
              <ProgressStep notDelivered={true}></ProgressStep>
            </ProgressBarWrapper>
            <ScanHistoryDetail notDelivered={true}>
              <div style={{ marginTop: "20px" }}>
                <p>Delivered</p>
                <p></p>
              </div>
            </ScanHistoryDetail>
          </HistoryPointContainer>
          <HistoryPointContainer>
            <ScanHistoryDate>
              <div>
                <p style={{ marginBottom: "0" }}>
                  <b>{scan.date[0]}</b>
                </p>
                <p>{scan.date[1]}</p>
              </div>
            </ScanHistoryDate>
            <ProgressBarWrapper active={i === 0}>
              <ScanHistoryLocationIcon active={i === 0} attentionNeeded={attentionNeeded}>
                {renderIcon(i, scan)}
              </ScanHistoryLocationIcon>
              <ProgressStep
                point={i !== 0}
                endOfSteps={i === scanHistory.length - 1}
                active={i === 0}
                attentionNeeded={attentionNeeded}
              />
            </ProgressBarWrapper>
            <ScanHistoryDetail active={i === 0} attentionNeeded={attentionNeeded}>
              <div>
                <p>{scan.address}</p>
                <p>{scan.status}</p>
              </div>
            </ScanHistoryDetail>
          </HistoryPointContainer>
        </React.Fragment>
      );
    }
    return (
      <HistoryPointContainer key={`${scan}-${i}`}>
        <ScanHistoryDate>
          <div>
            <p style={{ marginBottom: "0" }}>
              <b>{scan.date[0]}</b>
            </p>
            <p>{scan.date[1]}</p>
          </div>
        </ScanHistoryDate>
        <ProgressBarWrapper active={i === 0}>
          <ScanHistoryLocationIcon active={i === 0} attentionNeeded={attentionNeeded}>
            {renderIcon(i, scan)}
          </ScanHistoryLocationIcon>
          <ProgressStep
            point={i !== 0}
            endOfSteps={i === scanHistory.length - 1}
            active={i === 0}
            attentionNeeded={attentionNeeded}
          ></ProgressStep>
        </ProgressBarWrapper>
        <ScanHistoryDetail active={i === 0} attentionNeeded={attentionNeeded}>
          <div>
            <p>{scan.address}</p>
            <p>{scan.status}</p>
          </div>
        </ScanHistoryDetail>
      </HistoryPointContainer>
    );
  });
};

export const PackageDetails = ({ onClose, pkg, index, count }) => {
  const [viewAmount, setViewAmount] = useState(5);

  const handleViewableHistory = () => {
    viewAmount === 5 ? setViewAmount(pkg.history?.length) : setViewAmount(5);
  };

  const packageStatus = () => {
    if (pkg.attentionNeeded) {
      return (
        <StatusColor attentionNeeded={pkg.attentionNeeded}>
          <b>{pkg.status[1]}</b>
          <b> {pkg.status[0]}</b>
        </StatusColor>
        )
    } else {
      return (
        <b>{pkg.status}</b>
      )
    }
  }

  return (
    <Container>
      <CurtainHeader>
        <span>Additional Package Details</span>
      </CurtainHeader>
      <PackageType>
        <h5>{pkg.packageType} Package</h5>
        <span>
          {index} of {count}
        </span>
      </PackageType>
      <Details>
        <Detail>
          <DetailHeader>Status</DetailHeader>
          <StatusWrapper>
            <p>
              {pkg.status.length > 1 ? (
                packageStatus()
              ) : (
                <span> {pkg.status}</span>
              )}
            </p>
          </StatusWrapper>
        </Detail>
        <Detail>
          <DetailHeader>Tracking Number</DetailHeader>
          <p>{pkg.tracking}</p>
        </Detail>
        <Detail>
          <DetailHeader>Shipper Name</DetailHeader>
          <p>{pkg.from ? pkg.from : "N/A"}</p>
        </Detail>
        <Detail>
          <DetailHeader>Origin</DetailHeader>
          <p>{pkg.origin.city ? `${pkg.origin?.city}, ${pkg.origin?.state}` : "N/A"}</p>
        </Detail>
        <Detail>
          <DetailHeader>Mail Service Class</DetailHeader>
          <p>Priority Mail® — USPS Tracking® Up to $100 insurance included</p>
        </Detail>
        {pkg.rideAlongUrl && (
          <RideAlong>
            <img src={pkg.rideAlongUrl} alt="placeholder" />
          </RideAlong>
        )}
        <ScanHistoryTableWrapper>
          <h3>Scan History</h3>
          {pkg.history ? (
            <ScanHistoryTable scanHistory={pkg.history} attentionNeeded={pkg.attentionNeeded} viewAmount={viewAmount} />
          ) : (
            <div>No history available</div>
          )}
          {pkg.history?.length > 5 ? (
            <Button text onClick={handleViewableHistory}>
              {viewAmount === 5 ? "See More" : "See Less"}
            </Button>
          ) : null}
        </ScanHistoryTableWrapper>
      </Details>

      <ButtonWrapper>
        <Button secondary onClick={onClose}>
          Go Back
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

PackageDetails.propTypes = {
  onClose: PropTypes.func,
  pkg: PropTypes.object,
  index: PropTypes.number,
  count: PropTypes.number
}