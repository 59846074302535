import { CheckBox } from "@informed-innovations/components";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  width: 290px;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 15px;

  @media screen and (max-width: 960px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 290px) {
    width: 240px;
    margin-left: 0;
  }
`;

export const OptionField = React.forwardRef(({ value, title, list, setList, disable, ...props }, ref) => {
  const handleCheck = () => {
    if (list.includes(title)) {
      setList((prev) => [...prev.filter((item) => item !== title)]);
    } else {
      setList((prev) => [...prev, title]);
    }
  };
  return (
    <Container title={title} aria-label={value}>
      {title}
      <CheckBox
        value={value}
        disabled={!disable}
        checked={!!list.includes(title) && disable}
        onChange={handleCheck}
        {...props}
        ref={ref}
      />
    </Container>
  );
});

OptionField.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  list: PropTypes.array,
  setList: PropTypes.func,
  disable: PropTypes.bool
}