export const DeliveredIcon = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.172" height="23.868" viewBox="0 0 29.172 23.868" {...props}>
      <path
        id="home_work_FILL0_wght400_GRAD0_opsz48"
        d="M61.78,223.194h1.989V221.2H61.78Zm0,5.669h1.989v-1.989H61.78Zm0,5.636h1.989v-1.989H61.78Zm-1.856,5.37v-1.989h7.26v-19.89H54.089V221.7L52.1,220.31V216H69.172v23.868Zm-19.923,0V226.94l8.984-6.431,8.951,6.431v12.929H50.741V233.2H47.227v6.663Zm1.989-1.989h3.249v-6.663H52.73v6.663h3.216v-9.912l-6.962-5.006-6.995,5.02ZM59.923,225.779Zm-7.194,12.1v-6.663H45.238v0H52.73Z"
        transform="translate(-40 -216)"
        fill={fill ? fill : "#3573b1"}
      />
    </svg>
  );
};
