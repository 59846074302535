import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "../services/api";
import { pingApi } from "../services/pingApi";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [pingApi.reducerPath]: pingApi.reducer,
});

const preloadedState = {};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(pingApi.middleware),
  preloadedState: preloadedState,
});

store.subscribe(() => {});

setupListeners(store.dispatch);
