import { SESSION_KEY, AUTH_URL, CLIENT_ID } from "../config/constants";
import { User } from "oidc-client-ts";
import { jwtDecode } from "jwt-decode";

export const getAuthorizationHeader = () => {
  localStorage.setItem(SESSION_KEY.MILLIS, Date.now());
  const oidcStorage = sessionStorage.getItem(`oidc.user:${AUTH_URL}:${CLIENT_ID}`);
  if (!oidcStorage) {
    return "";
  }
  const user = User.fromStorageString(oidcStorage);
  return user ? user.token_type + " " + user.access_token : "";
};

export const getUserInfo = () => {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${AUTH_URL}:${CLIENT_ID}`);
  if (!oidcStorage) {
    return {};
  }
  const user = User.fromStorageString(oidcStorage);
  return user ? user.profile : {};
};

const decodeJwt = (oidcStorage) => {
  const user = User.fromStorageString(oidcStorage);
  if (!user) return false;
  return jwtDecode(`${user.token_type} ${user.access_token}`);
};

export const hasRole = (role) => {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${AUTH_URL}:${CLIENT_ID}`);
  if (!oidcStorage) return false;
  const decoded = decodeJwt(oidcStorage);
  if (!decoded) return false;
  const roles = decoded?.realm_access?.roles || [];
  return roles.includes(role);
};

export const getZip11 = () => {
  const zeroZip11= "00000000000"
  const oidcStorage = sessionStorage.getItem(`oidc.user:${AUTH_URL}:${CLIENT_ID}`);
  if (!oidcStorage) return zeroZip11;

  const decoded = decodeJwt(oidcStorage);
  if (!decoded) return zeroZip11;

  const userInfo = getUserInfo();
  if (!userInfo?.addresses) return zeroZip11;

  const address = Array.isArray(userInfo.addresses) ? userInfo.addresses[0] : userInfo.addresses;
  const zipCode = address.ZIPCode || "00000";
  const zipPlus4 = address.ZIPPlus4 || "0000";
  const deliveryPoint = address.deliveryPoint || "00";
  const constructedZip11 = zipCode + zipPlus4 + deliveryPoint;

  return constructedZip11 === decoded.id_zip11 ? constructedZip11 : zeroZip11;
};

export const getFirstName = () => {
  const userInfo = getUserInfo();
  return userInfo?.given_name || "User";
};

export const getAddress = () => {
  const userInfo = getUserInfo();
  if (!userInfo?.addresses) {
    return "No Address Available";
  }
  const addresses = Array.isArray(userInfo.addresses) ? userInfo.addresses : [userInfo.addresses];
  const { streetAddress, secondaryAddress, city, state, ZIPCode } = addresses[0];
  const parts = [streetAddress, secondaryAddress, city, state, ZIPCode].filter((part) => part.length > 0);
  return parts.join(", ");
};
