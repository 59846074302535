export const MOCK_DATA = process.env.REACT_APP_MOCK_DATA ? process.env.REACT_APP_MOCK_DATA : "";

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8080";

export const AUTH_URL = process.env.REACT_APP_AUTH_URL
  ? process.env.REACT_APP_AUTH_URL
  : "https://dev-auth.uspsinnovation.tech/auth/realms/informed";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : "web_app";

export const INFORMED_IMAGES_URL = process.env.REACT_APP_INFORMED_IMAGES_URL
  ? process.env.REACT_APP_INFORMED_IMAGES_URL
  : "https://informedimages.com";

export const INFORMED_COUPONS_URL = process.env.REACT_APP_INFORMED_COUPONS_URL
  ? process.env.REACT_APP_INFORMED_COUPONS_URL
  : "https://informedcoupons.com";

export const INFORMED_DELIVERY_URL = process.env.REACT_APP_INFORMED_DELIVERY_URL
  ? process.env.REACT_APP_INFORMED_DELIVERY_URL
  : "https://informeddelivery.usps.com";

export const INFORMED_DELIVERY_BUSINESS_URL = process.env.REACT_APP_INFORMED_DELIVERY_BUSINESS_URL
  ? process.env.REACT_APP_INFORMED_DELIVERY_BUSINESS_URL
  : "https://www.usps.com/business/informed-delivery.htm";

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID ? process.env.REACT_APP_GA_MEASUREMENT_ID : "";

export const ROLES = {
  IDBUS_USER: "IDBus_User",
};

export const SESSION_KEY = {
  MILLIS: "MILLIS",
  FIRST_NAME: "firstName",
};

export const MAX_MAIL_PIECES = 10;
export const MAX_INBOUND_PACKAGES = 10;
export const MAX_OUTBOUND_PACKAGES = 10;
