import styled from "styled-components";
import { Spinner } from "@informed-innovations/components";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
`;

export const Loading = (props) => {
  return (
    <LoadingContainer {...props}>
      <Spinner />
    </LoadingContainer>
  );
};
