import styled from "styled-components";
import { FilterListItem } from "./FilterListItem";
import { Button, Theme } from "@informed-innovations/components";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  column-gap: 10px;
  margin-right: 75px;
  @media screen and (max-width: 960px) {
    justify-content: left;
    padding-top: 10px;
  }
`;

const ResetButton = styled(Button)`
  padding: 0;
  width: 100px;
  min-width: 0px;
  color: ${Theme.colors.cyan};
  text-decoration: underline;
`;

export const FilterList = ({ filterList, setFilterList, setPackageType, setFilterState, filterInitialState }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const renderedFilterItems = [];
  const onRemoveItem = (item) => {
    setFilterList((prev) => [...prev.filter((filter) => filter !== item)]);
  };
  if (filterList) {
    renderedFilterItems.push(
      ...filterList.map((item) => {
        if (!item.includes("Inbound") && !item.includes("Outbound")) {
          return (
            <FilterListItem
              key={`filter-${item}`}
              filter={item}
              onRemove={() => {
                // TODO check this works when site is up
                onRemoveItem(item);
              }}
            />
          );
        }
        return <span key={`filter-${item}`} />;
      })
    );
  }
  return (
    <>
      <Container>
        {renderedFilterItems}
        {isDesktop && (
          <ResetButton
            text
            onClick={() => {
              setFilterState(filterInitialState);
              setPackageType(["Inbound", "Outbound"]);
              setFilterList([]);
            }}
          >
            Reset
          </ResetButton>
        )}
      </Container>

      {!isDesktop && (
        <ResetButton
          text
          onClick={() => {
            setFilterState(filterInitialState);
            setPackageType(["Inbound", "Outbound"]);
            setFilterList([]);
          }}
        >
          Reset
        </ResetButton>
      )}
    </>
  );
};

FilterList.propTypes = {
  filterList: PropTypes.array,
  setFilterList: PropTypes.func,
  setPackageType: PropTypes.func,
  setFilterState: PropTypes.func,
  filterInitialState: PropTypes.array,
};
