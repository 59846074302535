import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { arrayBufferToBase64 } from "../common/utils";
import { API_URL, MAX_INBOUND_PACKAGES, MAX_MAIL_PIECES, MAX_OUTBOUND_PACKAGES } from "../config/constants";
import { getAuthorizationHeader, getZip11 } from "./apiHelpers";

const secureImageQueryFn = async (data) => {
  const headers = new Headers();
  headers.set("authorization", getAuthorizationHeader());

  const response = await fetch(data.data, { headers });
  const arrayBuffer = await response.arrayBuffer();

  return { data: `data:${response.headers.get("Content-Type")};base64,${arrayBufferToBase64(arrayBuffer)}` };
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + "/informed-delivery",
    prepareHeaders: async (headers) => {
      headers.set("authorization", getAuthorizationHeader());
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMailpieces: builder.query({
      query: (params) => `/v1/mail-pieces/${getZip11()}/${params.date}?source=SBD&maxMailPieces=${MAX_MAIL_PIECES}`,
    }),
    getInboundPackages: builder.query({
      query: (params) =>
        `/v1/inbound-packages/${getZip11()}/${params.date}?source=SBD&maxInboundPackages=${MAX_INBOUND_PACKAGES}`,
    }),
    getOutboundPackages: builder.query({
      query: (params) =>
        `/v1/outbound-packages/${getZip11()}/${params.date}?source=SBD&maxOutboundPackages=${MAX_OUTBOUND_PACKAGES}`,
    }),
    getDashboard: builder.query({
      query: (params) =>
        `/v1/dashboard/${getZip11()}/${
          params.date
        }?source=SBD&maxMailPieces=${MAX_MAIL_PIECES}&maxInboundPackages=${MAX_INBOUND_PACKAGES}&maxOutboundPackages=${MAX_OUTBOUND_PACKAGES}`,
    }),
    postOutboundPackages: builder.mutation({
      query: (options) => {
        // append zip11 to options object
        const data = { ...options, ZIP11: getZip11() };
        return {
          url: `v2/dashboard/packages/outbound/search`,
          method: "POST",
          body: data,
        };
      },
    }),
    postInboundPackages: builder.mutation({
      query: (options) => {
        // append zip11 to options object
        const data = { ...options, ZIP11: getZip11() };
        return {
          url: `v2/dashboard/packages/inbound/search`,
          method: "POST",
          body: data,
        };
      },
    }),
    postMailpieces: builder.mutation({
      query: (options) => {
        // append zip11 to options object
        const data = { ...options, ZIP11: getZip11() };
        console.log(data);
        return {
          url: `v2/dashboard/mail-pieces/search`,
          method: "POST",
          body: data,
        };
      },
    }),
    postDashboard: builder.mutation({
      query: (options) => {
        // append zip11 to options object
        const data = { ...options, ZIP11: getZip11() };
        return {
          url: `v2/dashboard/search`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSecureImage: builder.query({
      queryFn: secureImageQueryFn,
    }),
  }),
});

export const {
  useLazyGetMailpiecesQuery,
  useLazyGetInboundPackagesQuery,
  useLazyGetOutboundPackagesQuery,
  useLazyGetDashboardQuery,
  useGetSecureImageQuery,
  usePostInboundPackagesMutation,
  usePostOutboundPackagesMutation,
  usePostMailpiecesMutation,
  usePostDashboardMutation,
} = api;
