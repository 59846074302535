export const FacilityIcon = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.694" height="21.325" viewBox="0 0 23.694 21.325" {...props}>
      <path
        id="corporate_fare_FILL0_wght400_GRAD0_opsz48"
        d="M80,237.325V216H91.551v4.887h12.143v16.438Zm1.777-1.777h8v-3.11h-8Zm0-4.887h8v-3.11h-8Zm0-4.887h8v-3.11h-8Zm0-4.887h8v-3.11h-8Zm9.774,14.661h10.366V222.664H91.551Zm2.369-8v-1.777h4.887v1.777Zm0,4.887v-1.777h4.887v1.777Z"
        transform="translate(-80 -216)"
        fill={fill ? fill : "#3573b1"}
      />
    </svg>
  );
};
