import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Card from "../shared/Card";
import { GlobeIcon, Theme } from "@informed-innovations/components";
import { MagnifyingGlassIcon } from "../../common/components/icons/MagnifyingGlassIcon";
import { NoImageIcon } from "../../common/components/icons/NoImageIcon";
import { AuthSecureImage } from "../../common/components/AuthSecureImage";
import PropTypes from "prop-types";

const CardContainer = styled.div`
  padding-top: 0px;
  background-color: ${Theme.colors.gray[100]};
  border-radius: 6px;
  position: relative;
  ${(props) =>
    !props.image &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}
`;

const CardTitle = styled.div`
  line-height: normal;
  padding: 20px;
  font-size: 18px;
  color: ${Theme.colors.black};
  white-space: pre;
  height: 32px;
  &:focus-visible {
    outline: 3px solid ${Theme.colors.cyan};
    transition: outline 0.15s ease;
  }
  b {
    color: ${Theme.colors.blue};
  }
`;

const CardImageContainer = styled.div`
  margin: auto;
  text-align: center;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;

  @media screen and (max-width: 425px) {
    width: 100%;
    max-height: 100%;
    padding: 15px;
  }
`;

const CardImage = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  content: "text";
`;

const RideAlongImageContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
  width: 399px;
  background-color: white;
  display: flex;
  img {
    max-width: 200px;
  }
  span {
    padding-left: 20px;
  }

  @media screen and (max-width: 960px) {
    span {
      font-size: 16px;
    }
    svg {
      width: 25px;
    }
    width: 80%;
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 460px) {
    img {
      padding-bottom: 20px;
    }
  }
`;

const DesktopGlobe = styled(GlobeIcon)`
  width: 25px;
  margin-left: 10px;
  display: inline;
  @media screen and (max-width: 460px) {
    display: none;
  }
`;

const MobileGlobe = styled(GlobeIcon)`
  width: 25px;
  display: none;
  @media screen and (max-width: 460px) {
    display: inline;
    margin-left: -15px;
    margin-right: 5px;
  }
`;

const ImageWrapper = styled.div`
  background-color: rgba(51, 51, 102);
  width: 100%;
  position: relative;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  color: black;
  padding: 30px;
  text-align: center;
`;

const EnlargeOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 102, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 30px;
  padding-top: 40px;
  text-align: center;
  cursor: pointer;
  div {
    display: block;
    margin: auto;
    opacity: 1;
    color: ${Theme.colors.white};
  }
  p {
    padding-top: 10px;
  }

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const DesignCard = ({ mail, setShowMailModal, onImageClick, imageMissing }) => {
  const imageRef = useRef();
  const imageContain = useRef();
  const [showEnlarge, setShowEnlarge] = useState(false);
  //TODO: check revision works when site is back up
  const mailImage = () => {
    if (mail.campaign?.replacementImage) {
      return mail.campaign.replacementImage.links[0].href;
    } else if (mail.image) {
      return mail.image.links[0].href;
    } else {
      return "";
    }
  };
  //  mail.campaign?.replacementImage ? mail.campaign.replacementImage.links[0].href : mail.image ? mail.image.links[0].href : "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeContent = useCallback(() => {
    if (!imageRef.current) return;
    if (imageContain.current.getBoundingClientRect().height === 300) return;
    imageRef.current.style.height = imageContain.current.getBoundingClientRect().height + `px`;
  });

  useEffect(() => {
    resizeContent();
  }, [resizeContent]);

  const generateImage = (mailImage) => {
    return <AuthSecureImage imageData={mailImage} component={CardImage} alt={"alt"} />;
  };

  return (
    <CardContainer key={mail.id} image={!!mail.image || !!mail.campaign} ref={imageContain}>
      <Card>
        {" "}
        {mail.campaign && mail.campaign.brandDisplayName && (
          <CardTitle>
            <b>From: </b>
            {mail.campaign.brandDisplayName}
          </CardTitle>
        )}
        {mail && (mail.image || mail.campaign) && (
          <CardImageContainer>
            <div>
              {mailImage.includes("image-no-mailpiece") && imageMissing === false ? (
                <NoImage>
                  <NoImageIcon />
                  <p>
                    There is one or more mailpiece(s) for which we do not currently have an image that is included in
                    today's mail.
                  </p>
                </NoImage>
              ) : (
                <ImageWrapper
                  onMouseEnter={() => setShowEnlarge(true)}
                  onMouseLeave={() => setShowEnlarge(false)}
                  onClick={() => {
                    setShowMailModal(true);
                    onImageClick();
                  }}
                >
                  {generateImage(mailImage)}
                  {showEnlarge && (
                    <EnlargeOverlay>
                      <div>
                        <MagnifyingGlassIcon />
                        <p>Enlarge</p>
                      </div>
                    </EnlargeOverlay>
                  )}
                </ImageWrapper>
              )}

              {mail.campaign?.rideAlong && (
                <RideAlongImageContainer>
                  <a
                    href={mail.campaign.rideAlong.links[1].href}
                    target="_blank"
                    rel="noreferrer"
                    alt="Ride-Along Image Link"
                  >
                    <AuthSecureImage
                      imageData={mail.campaign.rideAlong.links[0].href}
                      component={CardImage}
                      alt={"alt"}
                    />
                  </a>
                  <span>
                    <MobileGlobe />
                    <a href={mail.campaign.links[0].href} target="_blank" rel="noreferrer" alt="Ride-Along Image Link">
                      Learn More
                    </a>
                    <DesktopGlobe />
                  </span>
                </RideAlongImageContainer>
              )}
            </div>
          </CardImageContainer>
        )}
      </Card>
    </CardContainer>
  );
};

DesignCard.propTypes = {
  mail: PropTypes.object,
  setShowMailModal: PropTypes.func,
  onImageClick: PropTypes.func,
  imageMissing: PropTypes.bool,
};
