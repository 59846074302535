import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./app/App";
import { store } from "./app/store";
import ReactGA from "react-ga4";
import "./index.css";
import { GA_MEASUREMENT_ID } from "./config/constants";

let trackingIds = [{ trackingId: "GTM-MVCC8H" }];

if (GA_MEASUREMENT_ID && GA_MEASUREMENT_ID.length !== 0) {
  trackingIds.push({
    trackingId: GA_MEASUREMENT_ID,
  });
  ReactGA.initialize(GA_MEASUREMENT_ID, { debug: true });
}

ReactGA.initialize(trackingIds);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
