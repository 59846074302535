import styled from "styled-components";
import { Button, Theme } from "@informed-innovations/components";
import informedDeliveryBusinessDashboard from "../../static/img/informed-delivery-business-dashboard-desktop.png";
import informedDeliveryBusinessDashboardMobile from "../../static/img/informed-delivery-business-dashboard-mobile.png";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const HeroContainer = styled.div`
  background-image: url(${informedDeliveryBusinessDashboard});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${Theme.colors.gray[200]};

  height: 560px;
  max-height: 560px;
  img {
    width: 100%;
    max-height: 450px;
  }

  @media screen and (max-width: 960px) {
    background-image: unset;
    height: 100%;
    max-height: 100%;
  }
`;

const HeroInnerContainer = styled.div`
  width: 100%;
  max-width: 1365px;
  height: 100%;
  margin: 0 auto;
`;

const HeroContentContainer = styled.div`
  position: relative;
  z-index: 0;
  padding: 40px;

  p {
    max-width: 580px;
    margin-bottom: 0;
  }
  button,
  a {
    max-width: 170px;
    margin-top: 30px;
  }

  &::after {
    z-index: -1;
    background: ${Theme.colors.blue};
    content: "";
    top: 15%;
    bottom: 15%;
    left: -50%;
    width: 100%;
    position: absolute;
    transform: skew(-12deg);
  }

  @media screen and (max-width: 1256px) {
    &:after {
      left: -45%;
    }
  }

  @media screen and (max-width: 1124px) {
    &:after {
      left: -40%;
      background-position: left;
    }
  }
  @media screen and (max-width: 960px) {
    background: ${Theme.colors.blue};
    p {
      max-width: 100%;
    }
    &::after {
      background: unset;
      content: "";
      width: 0;
      height: 0;
      position: static;
    }
  }

  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: white;
    margin-bottom: 25px;
  }
  p {
    color: white;
    font-size: 16px;
  }

  @media screen and (max-width: 960px) {
    align-items: center;
  }

  @media screen and (max-width: 960px) {
    button,
    a {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const HeroImage = styled.img`
  display: none;
  object-fit: cover;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const FeatureContainer = styled.div`
  h2 {
    font-size: 28px;
    margin-bottom: 25px;
  }

  ul {
    padding-left: 15px;
  }

  @media screen and (max-width: 960px) {
    h2 {
      text-align: center;
    }
    p:first-of-type {
      margin-bottom: 25px;
    }
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: between;
  gap: 25px;
  font-size: 16px;
  > * {
    margin: 0;
    max-width: 50%;
    &:not(:last-child) {
      padding-right: 25px;
    }
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    flex-wrap: none;
    gap: 0;
    line-height: 2;
    justify-content: center;
    > * {
      max-width: 100%;
      &:not(:last-child) {
        padding-right: 25px;
      }
    }
  }
`;

const Col = styled.div`
  display: block;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1365px;
  margin-bottom: 60px;

  padding: 0 40px;

  @media screen and (max-width: 960px) {
    margin-bottom: 40px;
  }
`;

export const Splash = () => {
  const navigate = useNavigate();

  const handleHistory = () => {
    navigate(`/dashboard`);
  };

  return (
    <Container>
      <HeroContainer>
        <HeroInnerContainer>
          <HeroImage src={informedDeliveryBusinessDashboardMobile} alt="" />
          <HeroContentContainer>
            <h1>Informed Delivery Business Dashboard</h1>
            <p>
              The Informed Delivery Business Dashboard provides selected business account users the ability to view the
              status of inbound and outbound packages and images of inbound mail associated with their enrolled business
              address.
            </p>
            <br />
            <p>
              If your business user account is enrolled in Informed Delivery for your business address, click 'Get
              Started' to login with your USPS.com business account credentials.
            </p>
            <Button primary outline="white" onClick={() => handleHistory()}>
              Get Started
            </Button>
          </HeroContentContainer>
        </HeroInnerContainer>
      </HeroContainer>
      <ContentContainer>
        <Row>
          <Col>
            <FeatureContainer>
              <h2>How it Works</h2>
              <p>
                Once your USPS.com business account is enrolled in Informed Delivery with your business address, users
                can login with their credentials and view their Informed Delivery Business Dashboard. The Informed
                Delivery Business Dashboard allows business users to quickly view the status of inbound and outbound
                packages, including any packages for which attention is needed. Additionally, business users can view
                images of inbound mail associated with their enrolled business address.
              </p>
            </FeatureContainer>
          </Col>
          <Col>
            <FeatureContainer>
              <h2>Benefits</h2>
              <ul>
                <li>
                  Provide business users with summary information to quickly highlight current status of inbound and
                  outbound packages.{" "}
                </li>
                <li>
                  Provide immediate visibility to packages that may require business user action. For example, if
                  outbound package shipped to a customer has a delivery delay, the business user will see that
                  immediately upon login.
                </li>
                <li>
                  Provide business users with ability to see incoming mail images to better schedule trips to their
                  business location to pick up checks to be deposited, legal notices, and other critical correspondence.
                </li>
              </ul>
            </FeatureContainer>
          </Col>
        </Row>
      </ContentContainer>
    </Container>
  );
};
