import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { INFORMED_DELIVERY_URL } from "../config/constants";

const NoAccessContainer = styled.div`
  display: flex;
  margin-top: 12em;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  @media screen and (max-width: 600px) {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

const InnerPage = styled.div`
  padding-bottom: 84px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const NoAccess = () => {
  const [remainingTime, setRemainingTime] = useState(10);

  useEffect(() => {
    if (!remainingTime) {
      window.location.replace(INFORMED_DELIVERY_URL);
    }

    const intervalId = setInterval(() => {
      setRemainingTime(remainingTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  return (
    <InnerPage>
      <NoAccessContainer>
        <h3>Thanks for trying the Informed Delivery Business Dashboard.</h3>
        <br />
        <p>This application is only available for business users enrolled in Informed Delivery.</p>
        <br />
        <p>
          You will be redirected to <a href={INFORMED_DELIVERY_URL}>Informed Delivery</a> in {remainingTime} seconds.
        </p>
      </NoAccessContainer>
    </InnerPage>
  );
};
