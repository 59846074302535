import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { CloseIcon, GlobeIcon, Theme } from "@informed-innovations/components";
import { AuthSecureImage } from "../../common/components/AuthSecureImage";
import PropTypes from "prop-types";

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  height: 100%;
  padding-right: 20%;
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
  img {
    max-width: 100%;
  }
`;

const HoverImageContainer = styled.div`
  display: flex;
  padding: 25px;
  background-color: white;
  border: 1px solid black;
  width: 460px;
  img {
    max-width: 50%;
  }
  svg {
    width: 25px;
    margin-right: 10px;
  }
`;
const LearnMore = styled.div`
  padding: 0 20px;
`;

const XIconContainer = styled.div`
  position: absolute;
  transform: scale(1.25);
  margin: 0;
  padding: 0;
  top: 15px;
  right: 10px;
  cursor: pointer;
  svg {
    margin-right: 0;
  }
`;

const TooltipContainer = styled.div`
  .react-tooltip-arrow {
    display: none;
    border-bottom: 1px solid ${Theme.colors.black};
    border-right: 1px solid ${Theme.colors.black};
  }
`;

const NoImageDiv = styled.div`
  margin: auto;
`;

const CampaignImage = styled.img``;

export const RideAlongImage = ({ row, tippyId, isLast }) => {
  const handleClose = () => {
    window.dispatchEvent(
      new KeyboardEvent("keydown", {
        altKey: false,
        code: "Escape",
        ctrlKey: false,
        isComposing: false,
        key: "Escape",
        location: 0,
        metaKey: false,
        repeat: false,
        shiftKey: false,
        which: 27,
        charCode: 0,
        keyCode: 27,
      })
    );
  };

  if (!row.campaign || !row.campaign.rideAlong) {
    return <NoImageDiv>- -</NoImageDiv>;
  }

  return (
    <ImageContainer pointer={row.campaign.rideAlong.links[0].href}>
      <div
        autoFocus
        key={tippyId}
        data-tooltip-id={`ridealong-image-${tippyId}`}
        place="bottom"
        data-tooltip-offset={isLast ? -60 : -100}
      >
        <AuthSecureImage
          imageData={row.campaign.rideAlong.links[0].href}
          component={CampaignImage}
          alt={"Campaign Image"}
        />
      </div>
      <TooltipContainer>
        <Tooltip
          id={`ridealong-image-${tippyId}`}
          style={{
            marginTop: "16px",
            backgroundColor: "white",
            padding: 0,
            color: "black",
            zIndex: 1000,
            fontSize: "14px",
            opacity: 1,
            cursor: "default",
          }}
          clickable="true"
          closeOnEsc={true}
          render={() => (
            <HoverImageContainer>
              <XIconContainer onClick={() => handleClose()}>
                <CloseIcon />
              </XIconContainer>
              {row.campaign.rideAlong.links && row.campaign.rideAlong.links.length > 0 ? (
                <AuthSecureImage
                  imageData={row.campaign.rideAlong.links[0].href}
                  component={CampaignImage}
                  alt={"Campaign Image"}
                />
              ) : (
                "- -"
              )}
              <LearnMore>
                <GlobeIcon />
                <a href={row.campaign.links[0].href} target="_blank" rel="noreferrer" alt="alt">
                  Learn More
                </a>
              </LearnMore>
            </HoverImageContainer>
          )}
        />
      </TooltipContainer>
    </ImageContainer>
  );
};

RideAlongImage.propTypes = {
  row: PropTypes.object,
  tippyId: PropTypes.number,
  isLast: PropTypes.bool,
};
