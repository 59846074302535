export const ShippingLabelCreatedIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon-SS-Kiosk-Print-Shipping-Label"
      xmlns="http://www.w3.org/2000/svg"
      width="23.617"
      height="23.696"
      viewBox="0 0 23.617 23.696"
      {...props}
    >
      <path
        id="Icon-SS-Kiosk-Print-Shipping-Label-2"
        data-name="Icon-SS-Kiosk-Print-Shipping-Label"
        d="M742.5,6204.636l-3.852-4.542a.712.712,0,0,0-.548-.24H723.631a.711.711,0,0,0-.547.24l-3.83,4.542a.709.709,0,0,0-.175.461v17.729a.73.73,0,0,0,.722.725h22.172a.73.73,0,0,0,.723-.725V6205.1A.882.882,0,0,0,742.5,6204.636Zm-18.539-3.379H737.77l2.889,3.424h-7.442l-.569-2.436a.422.422,0,0,0-.416-.33h-2.714a.422.422,0,0,0-.415.33l-.591,2.436H721.07Zm5.363,6.5v-2.15h3.13v1.821h-1.358A5.2,5.2,0,0,0,729.322,6207.752Zm.241.746a1.9,1.9,0,0,0-.175.789v1.668H728.03a2.256,2.256,0,0,0-.92.263,4.142,4.142,0,0,1,1.313-2.04,4.717,4.717,0,0,1,.875-.57A1.388,1.388,0,0,1,729.562,6208.5Zm-.131-3.884.438-1.843h2.014l.438,1.843Zm-1.007,17.487H720.5v-16.544h7.923v2.655a4.967,4.967,0,0,0-2.276,4.169v8.118h2.276Zm.875,0v-1.623h3.13v1.623Zm0-2.436h-2.341v-6.8a1.1,1.1,0,0,1,1.095-1.1H730.2v-2.456a1.106,1.106,0,0,1,1.051-1.1h3.546v11.453H729.3Zm11.951,2.436h-7.923v-1.623H735.6v-13.056h-2.276v-1.865h7.923Zm-12.8-7.395h.438v3.643h-.438Zm1.051,0h.482v3.643H729.5Zm1.269,0h1.117v3.643h-1.117Zm1.905,0h.634v3.643h-.634Z"
        transform="translate(-719.078 -6199.854)"
        fill={fill ? fill : "#3573b1"}
      />
    </svg>
  );
};
