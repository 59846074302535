import styled from "styled-components";

const FooterContainer = styled.div`

    max-width: 100%;
    box-sizing: content-box;
    font-size: 11px !important;
 
    footer.global-footer ol {
        display: inline;
    }
 
    .global-footer--social li {
        display: inline-block;
        padding: 0 24px 0 0;
        height: 19px;
        line-height: 19px;
        text-align: center;
    }
 
    .global-footer--social li a img {
        width: 19px;
        vertical-align: middle;
        padding-bottom: 20px;
    }
 
    .global-footer--social li:nth-of-type(6) a img {
        margin-top: 3px;
    }
    .global-footer--navigation-options a {
        font-size: 11px !important;
        font-weight: normal;
    }
 
    .global-footer--copyright {
        font-size: 11px !important;
    }
`;
 
export const USPSGlobalFooter = () => {
    return (
        <FooterContainer id="global-footer--wrap" className="global-footer--wrap">
            <link
                type="text/css"
                rel="stylesheet"
                href="https://www.usps.com/global-elements/footer/css/footer-sb.css"
            />
            <footer className="global-footer">
                <a href="https://www.usps.com/" className="global-footer--logo-link" alt="USPS Icon">
                    {" "}
                </a>
                <nav className="global-footer--navigation">
                    <ol>
                        <li
                            style={{ color: "#333366", fontSize: "11px" }}
                            className="global-footer--navigation-category"
                        >
                            Helpful Links
                            <ol className="global-footer--navigation-options">
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://www.usps.com/help/contact-us.htm">Contact Us</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://www.usps.com/globals/site-index.htm">Site Index</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://faq.usps.com/s/">FAQs</a>
                                </li>
                                <li
                                    style={{ marginTop: "15px", width: "100%", color: "#333366", fontSize: "11px" }}
                                    className="global-footer--navigation-category"
                                >
                                    USPS Jobs
                                    <ol className="global-footer--navigation-options">
                                        <li style={{ fontSize: "11px !important" }}>
                                            <a href="https://about.usps.com/careers/">Careers</a>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li
                            style={{ color: "#333366", fontSize: "11px" }}
                            className="global-footer--navigation-category"
                        >
                            On About.USPS.com
                            <ol className="global-footer--navigation-options">
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/">About USPS Home</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/newsroom/">Newsroom</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/newsroom/service-alerts/">USPS Service Updates</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/resources/">Forms &amp; Publications</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/what/government-services/">Government Services</a>
                                </li>
                            </ol>
                        </li>
                        <li
                            style={{ color: "#333366", fontSize: "11px" }}
                            className="global-footer--navigation-category"
                        >
                            Other USPS Sites
                            <ol className="global-footer--navigation-options">
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://gateway.usps.com/">Business Customer Gateway</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://www.uspis.gov/">Postal Inspectors</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://www.uspsoig.gov/">Inspector General</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://pe.usps.com">Postal Explorer</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://postalmuseum.si.edu/">National Postal Museum</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://www.usps.com/business/web-tools-apis/">Resources for Developers</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://postalpro.usps.com/">PostalPro</a>
                                </li>
                            </ol>
                        </li>
                        <li
                            style={{ color: "#333366", fontSize: "11px" }}
                            className="global-footer--navigation-category"
                        >
                            Legal Information
                            <ol className="global-footer--navigation-options">
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/privacy-policy/">Privacy Policy</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/terms-of-use.htm">Terms of Use</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/foia/">FOIA</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/no-fear-act/">No FEAR Act/EEO Contacts</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/fair-chance-act/">Fair Chance Act</a>
                                </li>
                                <li style={{ fontSize: "11px !important" }}>
                                    <a href="https://about.usps.com/who/legal/accessibility-statement/">
                                        Accessibility Statement
                                    </a>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </nav>
                <div className="global-footer--copyright">Copyright © 2024 USPS. All Rights Reserved.</div>
                {/* <ul className="global-footer--social">
                    <li style={{ fontSize: "11px !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://www.facebook.com/USPS?rf=108501355848630"
                        >
                            <img
                                alt="Facebook social media icon."
                                src="https://www.usps.com/global-elements/footer/images/facebook_logo.png"
                            />
                        </a>
                    </li>
                    <li style={{ fontSize: "11px !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://www.instagram.com/uspostalservice/?hl=en"
                        >
                            <img
                                alt="instagram social media icon."
                                src="https://www.usps.com/global-elements/footer/images/instagram_logo.png"
                            />
                        </a>
                    </li>
                    <li style={{ fontSize: "11px !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://www.pinterest.com/uspsstamps/"
                        >
                            <img
                                alt="Pinterest social media icon."
                                src="https://www.usps.com/global-elements/footer/images/pinterest_logo.png"
                            />
                        </a>
                    </li>
                    <li style={{ fontSize: "11px !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://www.threads.net/@uspostalservice?hl=en"
                        >
                            <img
                                alt="Threads social media icon."
                                src="https://www.usps.com/global-elements/footer/images/threads_logo.png"
                            />
                        </a>
                    </li>
                    <li style={{ fontSize: "11px !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://twitter.com/usps"
                        >
                            <img
                                alt="X social media icon."
                                src="https://www.usps.com/global-elements/footer/images/x_logo.png"
                            />
                        </a>
                    </li>
                    <li style={{ fontSize: "11px; !important" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", fontSize: "11px" }}
                            href="https://www.youtube.com/usps"
                        >
                            <img
                                alt="Youtube social media icon."
                                src="https://www.usps.com/global-elements/footer/images/youtube_logo.png"
                            />
                        </a>
                    </li>
                </ul> */}
            </footer>
        </FooterContainer>
    );
};