import { Button, Theme, CloseIcon } from "@informed-innovations/components";
import styled from "styled-components";
import { OptionField } from "./OptionField";
import { packageInitialState } from "../shared/initialStates";
import PropTypes from "prop-types";

const Container = styled.div`
  padding: 50px 40px;
  padding-bottom: 10px;
  justify-content: center;
  @media only screen and (max-width: 960px) {
    padding: 40px 8px;
    padding-top: 0;
    width: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  h3 {
    margin-top: 15px;
    margin-bottom: 0;
    padding: 0;
  }
  h3:first-child {
    margin-top: 0px;
  }
  @media screen and (max-width: 960px) {
    margin-left: auto;
  }
`;

const FilterListContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  p {
    font-size: 12px;
    color: ${Theme.colors.gray[700]};
  }
  button {
    width: 50px;
    text-align: left;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

const DesktopReset = styled(Button)`
  padding: 0;
  width: 100px;
  min-width: 0px;
  color: ${Theme.colors.cyan};
  text-decoration: underline;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const MobileReset = styled(Button)`
  padding: 0;
  min-width: 0px;
  color: ${Theme.colors.cyan};
  text-decoration: underline;
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  transform: scale(1.25);
  @media only screen and (min-width: 961px) {
    display: none;
  }
  @media screen and (max-width: 290px) {
    margin-right: 40px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    padding-top: 20px;
  }
`;

const Section = styled.div`
  border-top: 1px solid ${Theme.colors.gray[400]};
  margin-top: 25px;
  p {
    padding-top: 25px;
  }
`;

export const FilterSearchForm = ({
  packageType,
  setPackageType,
  filterList,
  setFilterList,
  userData,
  setFilterState,
  filterState,
  filterInitialState,
  setShowFilterModal,
  ...props
}) => {
  const checkTag = (tag) => {
    for (let val of userData) {
      if (tag === "Inbound" || tag === "Outbound") {
        if (val.packageType.includes(tag)) {
          return true;
        }
      } else if (val.status?.includes(tag) && (tag !== "Inbound" || tag !== "Outbound")) {
        return true;
      }
    }
    return false;
  };

  return (
    <Container {...props}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Header>
          <h3>Filter Packages</h3>
          <CloseButton title="Close" onClick={() => setShowFilterModal(false)}>
            <CloseIcon />
          </CloseButton>
        </Header>

        <FilterListContainer>
          <div>
            <div>
              <p>TYPE</p>
              {Object.values(packageInitialState[0].options).map((option) => {
                return (
                  <OptionField
                    key={option.title}
                    title={option.title}
                    value={JSON.stringify(option.value)}
                    list={packageType}
                    setList={setPackageType}
                    disable={checkTag(option.title)}
                  />
                );
              })}
            </div>
            {filterState.map((obj) => {
              return (
                <Section key={obj.section}>
                  <p>{obj.section}</p>
                  {Object.values(obj.options).map((option) => {
                    return (
                      <OptionField
                        key={option.title}
                        title={option.title}
                        value={JSON.stringify(option.value)}
                        list={filterList}
                        setList={setFilterList}
                        disable={checkTag(option.title)}
                      />
                    );
                  })}
                </Section>
              );
            })}

            <MobileReset
              text
              onClick={() => {
                setFilterState(filterInitialState);
                setPackageType(["Inbound", "Outbound"]);
                setFilterList([]);
              }}
            >
              Reset
            </MobileReset>
          </div>

          <DesktopReset
            text
            onClick={() => {
              setFilterState(filterInitialState);
              setPackageType(["Inbound", "Outbound"]);
              setFilterList([]);
            }}
          >
            Reset
          </DesktopReset>
        </FilterListContainer>
      </Form>
    </Container>
  );
};

FilterSearchForm.propTypes = {
  packageType: PropTypes.array,
  setPackageType: PropTypes.func,
  filterList: PropTypes.array,
  setFilterList: PropTypes.func,
  userData: PropTypes.object,
  setFilterState: PropTypes.func,
  filterState: PropTypes.array,
  filterInitialState: PropTypes.array,
  setShowFilterModal: PropTypes.func,
}