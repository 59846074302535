import { Theme, Button } from "@informed-innovations/components";
import styled, { css } from "styled-components";
import { IncomingPackagesIcon } from "../../common/components/icons/IncomingPackagesIcon";
import { OutgoingPackagesIcon } from "../../common/components/icons/OutgoingPackagesIcon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'

const PackagesInnerWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  width: 50%;
  h1 {
    display: inline-block;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

const ActionParagraph = styled.p`
  color: ${Theme.colors.red};
`;

const TopRightIconContainer = styled.div`
  svg {
    position: absolute;
    top: 0px;
    right: 20px;
  }
`;

const ProgressDescription = styled.div`
  @media screen and (max-width: 960px) {
    p {
      margin-bottom: 30px;
    }
  }
`;

const CountWrapper = styled.div`
  ${(props) =>
    props.count &&
    css`
      padding-top: 82px;
    `}
  @media screen and (max-width: 960px) {
    h1 {
      margin-bottom: 30px;
    }
    padding-top: 20px;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  margin-left: -20px;
  bottom: 0;
  text-align: center;
  a {
    display: inline-block;
    min-width: 275px;
  }
  @media screen and (max-width: 960px) {
    position: static;
    margin-left: 0;
  }
  @media screen and (max-width: 360px) {
    button {
      min-width: 100%;
    }
  }
`;

export const AttentionPackages = ({ type, count }) => {
  const navigate = useNavigate();
  const [actionsNeeded /*setActionsNeeded*/] = useState(0);

  const handleHistory = (to) => {
    navigate(`/${to}`);
  };

  return (
    <PackagesInnerWrapper>
      <TopRightIconContainer>
        {type === "Inbound" ? (
          <IncomingPackagesIcon fill={Theme.colors.gray[400]} />
        ) : (
          <OutgoingPackagesIcon fill={Theme.colors.gray[400]} />
        )}
      </TopRightIconContainer>
      <h4>{type} Packages</h4>
      <ProgressDescription>
        {count === 0 && <p style={{ paddingTop: "48px" }}>You currently have no packages to display.</p>}
      </ProgressDescription>
      {type === "Inbound" ? (
        <ActionParagraph>{actionsNeeded > 0 ? `Attention needed on ${actionsNeeded} items.` : ""}</ActionParagraph>
      ) : actionsNeeded > 0 ? (
        <p>&nbsp;</p>
      ) : (
        ""
      )}

      <CountWrapper count={count !== 0}>
        <h1>{count}</h1>
      </CountWrapper>

      <ButtonWrapper>
        <Button primary onClick={() => handleHistory("packages")}>
          View All {type} Packages
        </Button>
      </ButtonWrapper>
    </PackagesInnerWrapper>
  );
};

AttentionPackages.propTypes = {
  type: PropTypes.string,
  count: PropTypes.number,
}