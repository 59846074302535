import React, { useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import informedCoupons from "../../static/img/informed-coupons.png";
import informedCouponsMobile from "../../static/img/informed-coupons-mobile.png";
import informedImages from "../../static/img/informed-images-no-background-slide1.png";
import smallBusinessSolutions from "../../static/img/small-business-solutions-slide3.png";
import informedDelivery from "../../static/img/informed-delivery-slide4.png";
import { Carousel } from "react-responsive-carousel";
import { Button, Theme } from "@informed-innovations/components";
import { INFORMED_IMAGES_URL, INFORMED_COUPONS_URL, INFORMED_DELIVERY_BUSINESS_URL } from "../../config/constants";
import PropTypes from "prop-types";

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  .carousel-slider {
    height: 300px;
  }
  .carousel .slide {
    max-width: 1285px;
    width: 100%;
  }
  .carousel .slide img {
    width: fit-content;
    justify-content: center;
  }

  .carousel img {
    justify-content: center;
  }

  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
    .carousel-slider {
      height: 100%;
      min-height: 100%;
    }
    .carousel .slide {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 360px) {
    .carousel .slide img {
      width: 100%;
      height: auto;
    }
  }
`;

const Slide = styled.div`
  background-color: ${Theme.colors.gray[100]};
  margin: 20px 0;
  max-height: 220px;
  width: 100%;
  text-align: left;
  display: table;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 960px) {
    max-height: 800px;
    display: block;
    background-color: ${Theme.colors.white};
  }
`;

const SlideDots = styled.li`
  &:hover {
    cursor: pointer;
  }
`;

const HeroInnerContainer = styled.div`
  display: table-cell;
  height: 100%;
  width: 50%;
  overflow: hidden;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    display: block;
    width: 100%;
  }
`;

const HeroContentContainer = styled.div`
  position: relative;
  z-index: 0;
  padding: 50px 30px;
  height: 220px;
  width: 100%;

  p {
    max-width: 500px;
    margin-bottom: 0;
  }
  button {
    max-width: 170px;
    margin-top: 30px;
  }

  &::after {
    z-index: -1;
    background: ${Theme.colors.blue};
    content: "";
    top: 10%;
    bottom: 10%;
    left: -12%;
    width: 100%;
    height: 175px;
    position: absolute;
    transform: skew(-12deg);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: white;
  }
  p {
    color: white;
    font-size: 16px;
  }

  @media screen and (max-width: 960px) {
    height: auto;
    padding: 30px 40px;
    background-color: ${Theme.colors.blue};
    h1 {
      text-align: center;
    }
    &::after {
      display: none;
    }
  }
`;

const PopoutImageContainer = styled.div`
  position: relative;
  display: table-cell;
  text-align: center;
  width: 25%;

  @media screen and (max-width: 960px) {
    width: 100%;
    position: static;
    text-align: center;
    display: block;

    max-width: 100%;
    height: auto;
    padding: 30px 0;
  }
`;

const PopoutImageWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -50px;
  vertical-align: middle;

  @media screen and (max-width: 960px) {
    position: static;
    margin-left: -20px;
  }
`;

const MobileImageContainer = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    width: 100%;
    position: static;
    text-align: center;
    display: block;

    max-width: 100%;
    height: auto;
    padding: 30px 0;
    display: block;
  }
`;

const UnderImageContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 25%;
  padding-top: 22px;
  z-index: -2;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const InformedCouponsImage = styled.div`
  background-image: url(${informedCoupons});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 175px;
  margin-left: -120px;
  margin-right: 20px;
  z-index: -2;
`;

const InformedDeliveryUnderImageContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: top;
  width: 25%;
  z-index: -2;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const InformedDeliveryImage = styled.div`
  background-image: url(${informedDelivery});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 220px;
  margin-left: -120px;
  z-index: -2;
`;

const InformedDeliveryContainer = styled.div`
  position: relative;
  z-index: 0;
  padding-left: 30px;
  padding-right: 60px;
  height: 220px;
  width: 100%;

  p {
    max-width: 500px;
    margin-bottom: 0;
  }
  button {
    max-width: 170px;
    margin-top: 30px;
  }

  &::after {
    z-index: -1;
    background: ${Theme.colors.blue};
    content: "";
    top: 0;
    bottom: 10%;
    left: -15%;
    width: 100%;
    height: 220px;
    position: absolute;
    transform: skew(-12deg);
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: white;
  }
  p {
    color: white;
    font-size: 16px;
  }
  @media screen and (max-width: 960px) {
    height: auto;
    padding: 30px 40px;
    background-color: ${Theme.colors.blue};
    h1 {
      text-align: center;
    }
    &::after {
      display: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  a {
    display: inline-block;
    min-width: 260px;
  }
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const ControlsContainer = styled.div`
  text-align: center;
  width: 100%;
  ul {
    padding-left: 0px;
    margin: 0;
  }
`;

export const DashboardSlideshow = ({ isDesktop }) => {
  const [slideState, setSlideState] = useState(0);
  let slideCount = [0, 1, 2, 3];
  const eventTrack = (category, action) => {
    ReactGA.event({
      category: category,
      action: action,
    });
  };

  const updatedCurrentSlide = (index) => {
    if (slideState !== index) {
      setSlideState(index);
    }
  };

  const renderControls = slideCount.map((number) => {
    if (number === slideState) {
      return (
        <SlideDots
          key={`slide-${number}`}
          style={{
            background: Theme.colors.blue,
            borderRadius: 30,
            width: 20,
            height: 20,
            display: "inline-block",
            margin: "0 8px",
          }}
          aria-label={`Selected: slide ${number + 1}`}
          title={`Selected: slide ${number + 1}`}
        />
      );
    } else {
      return (
        <SlideDots
          style={{
            background: "white",
            border: "2px solid #000",
            borderRadius: 30,
            width: "20px",
            height: "20px",
            display: "inline-block",
            margin: "0 8px",
          }}
          onClick={() => updatedCurrentSlide(number)}
          onKeyDown={() => updatedCurrentSlide(number)}
          value={number}
          key={`slide-${number}`}
          tabIndex={0}
          title={`slide ${number + 1}`}
          aria-label={`slide ${number + 1}`}
        />
      );
    }
  });

  return (
    <Container>
      <Carousel
        statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
        showThumbs={false}
        swipeable={() => isDesktop}
        showStatus={false}
        showArrows={false}
        autoPlay={true}
        interval={5000}
        infiniteLoop={true}
        onChange={updatedCurrentSlide}
        showIndicators={false}
        selectedItem={slideState}
      >
        <Slide>
          <HeroInnerContainer>
            <InformedDeliveryContainer>
              <h1>Informed Delivery for Business Mailer & Shippers</h1>
              <p>
                Informed Delivery interactive campaigns for packages is available now! Promote your brand, products, and
                services to Informed Delivery customers who are tracking your packages.
              </p>
            </InformedDeliveryContainer>
          </HeroInnerContainer>
          <InformedDeliveryUnderImageContainer>
            <InformedDeliveryImage />
          </InformedDeliveryUnderImageContainer>
          <MobileImageContainer>
            <img src={informedDelivery} alt="Placeholder" width="auto" height="240px" />
          </MobileImageContainer>

          <ButtonContainer>
            <Button
              primary
              href={INFORMED_DELIVERY_BUSINESS_URL}
              target="_blank"
              onClick={() => {
                eventTrack("Informed Delivery Business", "Informed Delivery Business Button");
              }}
            >
              Learn More
            </Button>
          </ButtonContainer>
        </Slide>
        <Slide>
          <HeroInnerContainer>
            <HeroContentContainer>
              <h1>Informed Coupons</h1>
              <p>
                Enhance your Informed Delivery interactive campaign with the new digital creation tool. Easily create
                your own custom coupon for customers to access via their Informed Delivery notifications.
              </p>
            </HeroContentContainer>
          </HeroInnerContainer>
          <UnderImageContainer>
            <InformedCouponsImage />
          </UnderImageContainer>
          <MobileImageContainer>
            <img src={informedCouponsMobile} alt="Placeholder" width="auto" height="240px" />
          </MobileImageContainer>

          <ButtonContainer>
            <Button
              primary
              href={INFORMED_COUPONS_URL}
              target="_blank"
              onClick={() => {
                eventTrack("Informed Coupons", "Informed Coupons Sign Up Button");
              }}
            >
              Sign up for free
            </Button>
          </ButtonContainer>
        </Slide>
        <Slide>
          <HeroInnerContainer>
            <HeroContentContainer>
              <h1>Informed Images</h1>
              <p>
                Our ride-along image templates can save you time and allow you to easily customize and download a
                ride-along image that meets all USPS Specifications for your Informed Delivery Marketing campaign.
              </p>
            </HeroContentContainer>
          </HeroInnerContainer>
          <PopoutImageContainer>
            <PopoutImageWrapper>
              <img src={informedImages} alt="Placeholder" width="auto" height="240px" />
            </PopoutImageWrapper>
          </PopoutImageContainer>

          <ButtonContainer>
            <Button
              primary
              href={INFORMED_IMAGES_URL}
              target="_blank"
              onClick={() => {
                eventTrack("Informed Images", "Informed Images Sign Up Button");
              }}
            >
              Sign up for free
            </Button>
          </ButtonContainer>
        </Slide>
        <Slide>
          <HeroInnerContainer>
            <HeroContentContainer>
              <h1>Small Business Solutions</h1>
              <p>
                Use our online shipping services to send packages and earn loyalty rewards. Plus, get help with direct
                mail advertising to grow your business.
              </p>
            </HeroContentContainer>
          </HeroInnerContainer>
          <PopoutImageContainer>
            <PopoutImageWrapper>
              <img src={smallBusinessSolutions} alt="Placeholder" width="auto" height="240px" />
            </PopoutImageWrapper>
          </PopoutImageContainer>

          <ButtonContainer>
            <Button
              primary
              href="https://www.usps.com/smallbusiness/welcome.htm"
              target="_blank"
              onClick={() => {
                eventTrack("Small Business Learn More", "Small Business Learn More Button");
              }}
            >
              Learn More
            </Button>
          </ButtonContainer>
        </Slide>
      </Carousel>

      <ControlsContainer>
        <ul>{renderControls}</ul>
      </ControlsContainer>
    </Container>
  );
};

DashboardSlideshow.propTypes = {
  isDesktop: PropTypes.bool,
};
