import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Theme, CloseIcon, Button, Spinner } from "@informed-innovations/components";
import { IncomingPackagesIcon } from "../../common/components/icons/IncomingPackagesIcon";
import { OutgoingPackagesIcon } from "../../common/components/icons/OutgoingPackagesIcon";
import DataTable from "react-data-table-component";
import { RideAlongImage } from "../shared/RideAlongImage";
import { CopyIcon } from "../../common/components/icons/CopyIcon.js";
import { Search } from "./Search";
import { FilterSearchForm } from "../packages/FilterSearchForm";
import { FilterList } from "./FilterList";
import { WelcomeHeader } from "../shared/WelcomeHeader";
import { filterInitialState } from "../shared/initialStates";
import { PackagesCard } from "./PackagesCard";
import { PackageDetails } from "./PackageDetails";
import { GreenCheckIcon } from "../../common/components/icons/GreenCheckIcon";
import { FacilityIcon } from "../../common/components/icons/FacilityIcon";
import { DeliveredIcon } from "../../common/components/icons/DeliveredIcon";
import { ShippingLabelCreatedIcon } from "../../common/components/icons/ShippingLabelCreatedIcon";
import { OutForDeliveryIcon } from "../../common/components/icons/OutForDeliveryIcon";
import { ArriveDepartPostOfficeIcon } from "../../common/components/icons/ArriveDepartPostOfficeIcon";
import { usePostDashboardMutation } from "../../services/api";
import moment from "moment";
import { PackagesPagination } from "./PackagesPagination";
import { PackagesSecondaryPagination } from "./PackagesSecondaryPagination";
import PropTypes from "prop-types";
import { MAX_MAIL_PIECES, MAX_INBOUND_PACKAGES, MAX_OUTBOUND_PACKAGES } from "../../config/constants.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CurtainHeader = styled.div`
  display: none;
  height: 53px;
  background: ${Theme.colors.blue};
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${Theme.colors.red};

  span {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;

const ContentContainer = styled.div`
  display: table;
  position: relative;
  margin: 0px auto;
  padding: 20px;
  padding-left: 30px;
  padding-top: 0;
  max-width: 1345px;
  width: 100%;
  z-index: 0;

  @media screen and (max-width: 960px) {
    padding-left: 20px;
  }
`;

const SearchAndFilterWrapper = styled.div`
  min-height: 125px;
  max-height: 300px;
  max-width: 1285px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 400px) {
    max-height: 100%;
  }
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const FilterButtonWrapper = styled.span`
  width: 50%;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 960px) {
    justify-content: center;
    overflow: scroll;
    width: 100%;
    gap: 20px;
  }

  @media screen and (max-width: 400px) {
    padding: 0 20px;
    padding-bottom: 20px;
    justify-content: start;
    overflow: scroll;
  }
`;

const SearchContainer = styled.div`
  background-color: ${Theme.colors.white};
  display: flex;
  gap: 20px;
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 34px;
  z-index: 2;

  @media screen and (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    width: 40%;
    margin-right: 0;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    padding-bottom: 0;
  }
`;

const TableContainer = styled.div`
  display: none;
  position: relative;
  .rdt_TableCell {
    cursor: default;
  }

  .rdt_TableBody {
    border-bottom: 1px solid ${Theme.colors.gray[300]};
  }

  @media only screen and (min-width: 961px) {
    display: block;
  }
`;

const LeftTab = styled.div`
  width: 20px;
  height: 100%;
  background-color: ${Theme.colors.red};
  position: relative;
`;

const DataContainer = styled.div`
  padding-top: 16px;
`;

const PackageDetailsContainer = styled.div`
  padding: 30px;
  background-color: ${Theme.colors.gray[100]};
  border-left: 14px ${Theme.colors.gray[100]} solid;

  ${(props) =>
    props.attentionNeeded &&
    css`
      background-color: rgb(244, 227, 219);
      border-left: 14px ${Theme.colors.red} solid;
    `}
  display: table;
  width: 100%;
  h1 {
    padding: 10px 0;
  }
`;

const ShipperWrapper = styled.div`
  display: block;
  width: 46%;
  border-right: 2px solid ${Theme.colors.gray[500]};
`;

const ShipperName = styled.div``;

const ShipperOrigin = styled.div``;

const ShipperServiceClass = styled.div``;

const ScanContainer = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 20px;
`;

const ScanHistoryWrapper = styled.div`
  width: 50%;
  padding-left: 40px;
  max-width: 50%;
  position: relative;
  padding-bottom: 20px;
  button {
    position: absolute;
    bottom: -16px;
    left: 130px;
    color: ${Theme.colors.indigo};
    text-decoration: underline;
    min-width: 0px;
    max-width: 130px;
    width: 100%;
  }
`;

const HistoryPointContainer = styled.div`
  display: flex;
`;

const ProgressBarWrapper = styled.div`
  z-index: 1;
  text-align: center;
  display: block;
  position: relative;
  ${(props) =>
    props.active &&
    css`
      left: -3px;
    `}
`;
const ProgressStep = styled.div`
  margin: 0 20px;
  background-color: ${Theme.colors.green};
  position: relative;
  width: 14px;
  height: 82px;
  ${(props) =>
    props.active &&
    props.attentionNeeded &&
    css`
      background-color: ${Theme.colors.red};
    `}
  ${(props) =>
    props.point &&
    css`
      ::after {
        content: "";
        width: 0.62em;
        height: 0.62em;
        transform: rotate(45deg);
        background: ${Theme.colors.green};
        position: absolute;
        z-index: 0;
        top: -5px;
        left: 2px;
      }
    `}
    ${(props) =>
    props.end &&
    css`
      ::before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 30px;
        background: ${Theme.colors.green};
        position: absolute;
        z-index: 0;
        bottom: -6px;
        left: 0;
      }
    `}
        ${(props) =>
    props.notDelivered &&
    css`
      background-color: ${Theme.colors.gray[400]};
    `}
`;

const ScanHistoryDate = styled.div`
  width: 110px;
`;

const ScanHistoryLocationIcon = styled.div`
  border: 4px solid ${Theme.colors.gray[100]};
  border-radius: 100px;
  margin: 0 auto;
  height: 50px;
  width: 50px;
  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.colors.green};
      border-color: ${Theme.colors.gray[100]};
    `}
  ${(props) =>
    props.attentionNeeded &&
    css`
      border-color: rgb(244, 227, 219);
    `}
        ${(props) =>
    props.attentionNeeded &&
    props.active &&
    css`
      background-color: ${Theme.colors.red};
    `}
    ${(props) =>
    props.notDelivered &&
    css`
      background-color: ${Theme.colors.white};
      border-color: ${Theme.colors.gray[400]};
    `}
    svg {
    margin-top: 8px;
  }
`;

const ScanHistoryDetail = styled.div`
  background-color: ${Theme.colors.white};
  border-radius: 10px;
  width: 100%;
  height: 100px;
  z-index: 0;
  display: flex;
  position: relative;
  ${(props) =>
    props.active &&
    css`
      background-color: ${Theme.colors.green};
      margin-left: -30px;
      color: ${Theme.colors.white};
      border-left: 9px solid rgb(244, 227, 219);
      border-bottom-left-radius: 0%;
    `}
  ${(props) =>
    props.attentionNeeded &&
    props.active &&
    css`
      background-color: ${Theme.colors.red};
    `}
        ${(props) =>
    props.notDelivered &&
    css`
      background-color: transparent;
      p {
        color: ${Theme.colors.gray[600]};
      }
    `}
    div {
    flex-direction: column;
    padding: 0 30px;
    margin: auto 0;
  }
  p {
    margin-bottom: 0;
  }
`;

const FilterModalContainer = styled.div`
  background-color: white;
  overflow-y: scroll;
  overscroll-behavior: contain;
  @media only screen and (min-width: 961px) {
    min-width: 400px;
    max-width: 400px;
    border-left: 1px solid #cccccc;
    button:first-child {
      display: block;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 20;
  }
  @media only screen and (max-width: 960px) {
    button:first-child {
      display: none;
    }
  }
`;

const CloseButton = styled.button`
  padding: 0;
  margin-top: 20px;
  margin-right: 30px;
  background: transparent;
  border: none;
  transform: scale(1.25);
  @media only screen and (min-width: 961px) {
    display: block;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const PackageFilterTypeContainer = styled.span`
  display: block;
  padding-bottom: 7px;
  border-bottom: ${(props) => (props.active ? `4px solid ${Theme.colors.red}` : "4px solid transparent")};
`;

const PackageFilterType = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 53px;
  border: 2px solid ${Theme.colors.gray[300]};
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.colors.blue};
  text-align: right;
  padding: 20px;
  margin-top: 30px;

  b {
    color: ${Theme.colors.black};
    font-size: 28px;
    margin-left: -5px;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${Theme.colors.gray[200]};
  }

  @media screen and (max-width: 960px) {
    width: 126px;
    height: 42px;
    font-size: 12px;
    padding: 5px;
    b {
      font-size: 20px;
    }
  }
`;

const MobileFilterListContainer = styled.div`
  min-height: 25px;
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const DesktopFilterListContainer = styled.div`
  min-height: 25px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const ComponentContainer = styled.div`
  text-align: center;
  padding: 40px 0;
  p {
    color: ${Theme.colors.black};
  }
  h4 {
    color: ${Theme.colors.gray[700]};
    font-size: 18px;
    margin-bottom: 30px;
  }
  svg {
    margin-top: 16px;
    transform: scale(2);
  }
`;

const PackageListContainer = styled.div`
  display: none;

  @media only screen and (max-width: 960px) {
    display: block;
  }
`;

const ShipperNum = styled.h1`
  position: relative;
  svg {
    cursor: pointer;
  }
`;

const CopiedMessage = styled.span`
  text-align: center;
  font-size: 16px;
  color: ${Theme.colors.black};
  svg {
    margin-top: -12px;
  }
  ::before {
    font-weight: normal;
    content: "Link Copied";
    position: absolute;
    margin-left: -30px;
    top: 50px;
  }
`;

const NoResultsComponent = () => {
  return (
    <ComponentContainer>
      <h4>No matching records found</h4>
    </ComponentContainer>
  );
};

const NoDataComponent = ({ packageType }) => {
  let type;
  if (packageType.includes("Inbound") && packageType.includes("Outbound")) {
    type = "";
  } else if (packageType.includes("Inbound")) {
    type = "Inbound";
  } else if (packageType.includes("Outbound")) {
    type = "Outbound";
  }
  return (
    <ComponentContainer>
      <h4>
        No {type.length > 0 ? type.toLowerCase() : ""} packages are available to display, please check back tomorrow.
      </h4>
      {type === "Inbound" && <IncomingPackagesIcon fill={Theme.colors.gray[300]} />}
      {type === "Outbound" && <OutgoingPackagesIcon fill={Theme.colors.gray[300]} />}
    </ComponentContainer>
  );
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const renderIcon = (i, data) => {
  if (data.status.includes("Facility")) {
    return <FacilityIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Shipping Label")) {
    return <ShippingLabelCreatedIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Out for delivery")) {
    return <OutForDeliveryIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  if (data.status.includes("Delivered")) {
    return <DeliveredIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
  }
  return <ArriveDepartPostOfficeIcon fill={i === 0 ? `${Theme.colors.white}` : `${Theme.colors.blue}`} />;
};

const ScanHistoryTable = ({ scanHistory, attentionNeeded, viewAmount }) => {
  const generateDate = (data) => {
    const month = monthNames[data[1] - 1].substring(0, 3);
    const day = data[2];
    const meridium = data[3] > 12 ? "pm" : "am";
    let hour;
    const minute = data[4] >= 10 ? data[4] : `0${data[4]}`;
    if (data[3] === 0) {
      hour = 12;
    } else if (data[3] > 12) {
      hour = data[3] - 12;
    } else {
      hour = data[3];
    }
    return (
      <div>
        <p style={{ marginBottom: "0" }}>
          <b>
            {month} {day}
          </b>
        </p>
        <p>
          {hour}:{minute} {meridium}
        </p>
      </div>
    );
  };

  return scanHistory.map((scan, i) => {
    if (i >= viewAmount) return <div key={`${scan}-${i}`}></div>;
    if (!scanHistory[i].status.includes("Delivered") && i === 0) {
      return (
        <React.Fragment key={`${scan}-${i}`}>
          <HistoryPointContainer>
            <ScanHistoryDate>
              <div>
                <p style={{ marginBottom: "0" }}>
                  <b></b>
                </p>
                <p> </p>
              </div>
            </ScanHistoryDate>
            <ProgressBarWrapper>
              <ScanHistoryLocationIcon notDelivered={true}>
                <DeliveredIcon fill={`${Theme.colors.gray[400]}`} />
              </ScanHistoryLocationIcon>
              <ProgressStep notDelivered={true}></ProgressStep>
            </ProgressBarWrapper>
            <ScanHistoryDetail notDelivered={true}>
              <div style={{ marginTop: "20px" }}>
                <p>Delivered</p>
                <p></p>
              </div>
            </ScanHistoryDetail>
          </HistoryPointContainer>
          <HistoryPointContainer>
            <ScanHistoryDate>{scan.eventOccurred && generateDate(scan.eventOccurred)}</ScanHistoryDate>
            <ProgressBarWrapper active={i === 0}>
              <ScanHistoryLocationIcon active={i === 0} attentionNeeded={attentionNeeded}>
                {renderIcon(i, scan)}
              </ScanHistoryLocationIcon>
              <ProgressStep
                point={i !== 0}
                end={i === scanHistory.length - 1}
                active={i === 0}
                attentionNeeded={attentionNeeded}
              />
            </ProgressBarWrapper>
            <ScanHistoryDetail active={i === 0} attentionNeeded={attentionNeeded}>
              <div>
                <p>{scan.status}</p>
                <p>{scan.location}</p>
              </div>
            </ScanHistoryDetail>
          </HistoryPointContainer>
        </React.Fragment>
      );
    }
    return (
      <HistoryPointContainer key={`${scan}-${i}`}>
        <ScanHistoryDate>{scan.eventOccurred && generateDate(scan.eventOccurred)}</ScanHistoryDate>
        <ProgressBarWrapper active={i === 0}>
          <ScanHistoryLocationIcon active={i === 0} attentionNeeded={attentionNeeded}>
            {renderIcon(i, scan)}
          </ScanHistoryLocationIcon>
          <ProgressStep
            point={i !== 0}
            end={i === scanHistory.length - 1}
            active={i === 0}
            attentionNeeded={attentionNeeded}
          ></ProgressStep>
        </ProgressBarWrapper>
        <ScanHistoryDetail active={i === 0} attentionNeeded={attentionNeeded}>
          <div>
            <p>{scan.status}</p>
            <p>{scan.location}</p>
          </div>
        </ScanHistoryDetail>
      </HistoryPointContainer>
    );
  });
};

const PaginationComponent = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
  setRowsAmount,
  paginationRowsPerPageOptions,
}) => (
  <PackagesPagination
    currentPage={currentPage}
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage - 1}
    onChangePage={onChangePage}
    setRowsAmount={setRowsAmount}
    onChangeRowsPerPage={onChangeRowsPerPage}
    paginationRowsPerPageOptions={paginationRowsPerPageOptions}
    ActionsComponent={PackagesPagination}
  />
);

const ExpandedDetailsContent = ({ data }, viewAmount, handleViewableHistory) => {
  // TODO check handleCopyTracking and green checkmark work properly
  if (!data) return;
  let linkCopied = "";
  const handleCopyTracking = (data) => {
    linkCopied = data;
    navigator.clipboard.writeText(`${data.trackingNumber}`);
    setTimeout(() => {
      linkCopied = "";
    }, "5000");
  };

  return (
    <PackageDetailsContainer attentionNeeded={data.attentionNeeded}>
      <ScanContainer>
        <ShipperWrapper>
          <h3>Additional Package Details</h3>
          <ShipperNum onClick={() => handleCopyTracking(data)}>
            {data.trackingNumber}{" "}
            {linkCopied === data && data.isExpanded ? (
              <CopiedMessage>
                <GreenCheckIcon />
              </CopiedMessage>
            ) : (
              <CopyIcon style={{ transform: "scale(1,-1)" }} />
            )}
          </ShipperNum>
          <ShipperName>
            <b>Shipper Name</b>
            <p>{data.shipperName ? data.shipperName : "N/A"}</p>
          </ShipperName>
          <ShipperOrigin>
            <b>Origin</b>
            <p>{data.origin && data.origin.city ? `${data.origin.city}, ${data.origin.state}` : "N/A"}</p>
          </ShipperOrigin>
          <ShipperServiceClass>
            <b>Mail Service Class</b>
            <p>{data.deliveryClass ? data.deliveryClass.split("<")[0] : "N/A"}</p>
          </ShipperServiceClass>
        </ShipperWrapper>
        <ScanHistoryWrapper>
          <h3>Scan History</h3>
          {data.history ? (
            <ScanHistoryTable
              scanHistory={data.history}
              attentionNeeded={data.attentionNeeded}
              viewAmount={viewAmount}
            />
          ) : (
            <div>No history available</div>
          )}
          {data.history?.length > 5 ? (
            <Button text onClick={() => handleViewableHistory(data)}>
              {viewAmount === 5 ? "See More" : "See Less"}
            </Button>
          ) : null}
        </ScanHistoryWrapper>
      </ScanContainer>
    </PackageDetailsContainer>
  );
};

export const Packages = () => {
  //reminder: set up loop to add 'isExpanded' and 'attentionNeeded' to future calls once we have real api

  const currentDate = moment().format("YYYY-MM-DD");

  const [getDashboard, getDashboardResponse] = usePostDashboardMutation();
  const [init, setInit] = useState(false);

  const [finalData, setFinalData] = useState();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showFilterList, setShowFilterList] = useState(false);
  const [userData, setUserData] = useState([]);
  const [searchInUse, setSearchInUse] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [rowsAmount, setRowsAmount] = useState(10);
  const [resetPaginationToggle] = useState(false);
  const [inboundCount, setInboundCount] = useState(0);
  const [outboundCount, setOutboundCount] = useState(0);
  const [packageType, setPackageType] = useState(["Inbound", "Outbound"]);
  const [filterList, setFilterList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterState, setFilterState] = useState(filterInitialState);
  const [showPackageDetails, setShowPackageDetails] = useState(false);
  const [packageToShow, setPackageToShow] = useState();
  const [viewAmount, setViewAmount] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const paginationRowsPerPage = [10, 20, 30, 40];
  const [rowCount, setRowCount] = useState();
  const [currentRow, setCurrentRow] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!init) {
      getDashboard({
        deliveryDate: currentDate,
        maxMailPieces: MAX_MAIL_PIECES,
        maxSaturation: 10,
        maxInboundPackages: MAX_INBOUND_PACKAGES,
        maxOutboundPackages: MAX_OUTBOUND_PACKAGES,
        campaigns: true,
      });
      setInit(true);
    }
  }, [getDashboard, init, setInit, currentDate]);

  useEffect(() => {
    if (getDashboardResponse.data) {
      console.log("getDashboardResponse.data");
      console.log(getDashboardResponse.data);
      if (
        getDashboardResponse.data.inboundPackages?.arrivingSoon ||
        getDashboardResponse.data.inboundPackages?.arrivingToday
      ) {
        setInboundCount(
          getDashboardResponse.data.inboundPackages?.arrivingSoon.length +
            getDashboardResponse.data.inboundPackages?.arrivingToday.length
        );
      }
      if (getDashboardResponse.data.outboundPackages) {
        setOutboundCount(getDashboardResponse.data.outboundPackages.length);
      }
      const summarizedData = [];
      if (getDashboardResponse.data.inboundPackages.length > 0) {
        for (let val of getDashboardResponse.data.inboundPackages?.arrivingSoon) {
          const pkg = {
            ...val,
            packageType: "Inbound",
            type: "inbound",
            deliveryClass: null,
            origin: {
              city: null,
              state: null,
              zip5: null,
            },
            detail: null,
            history: null,
            status: "Arriving Soon",
            summary: null,
            trackingComplete: null,
          };

          summarizedData.push(pkg);
        }
        for (let val of getDashboardResponse.data.inboundPackages?.arrivingToday) {
          const pkg = {
            ...val,
            packageType: "Inbound",
            type: "inbound",
            deliveryClass: null,
            origin: {
              city: null,
              state: null,
              zip5: null,
            },
            detail: null,
            history: null,
            status: "Arriving Today",
            summary: null,
            trackingComplete: null,
          };

          summarizedData.push(pkg);
        }
      }

      for (let val of getDashboardResponse.data.outboundPackages) {
        const pkg = {
          ...val,
          packageType: "Outbound",
          type: "outbound",
          deliveryClass: null,
          origin: {
            city: null,
            state: null,
            zip5: null,
          },
          detail: null,
          history: null,
          status: null,
          summary: null,
          trackingComplete: null,
        };

        summarizedData.push(pkg);
      }

      setIsLoading(false);
      const sortedData = sortByDate(summarizedData);
      setUserData(sortedData);
    }
  }, [getDashboardResponse]);

  //this will handle filtering and searching when filters are changed or the search bar is in use
  useEffect(() => {
    if (packageType.length === 0) {
      setFilteredItems([]);
      return;
    }
    setRowCount(userData.length);
    let result = [];

    //first create an initial state based on inbound/outbound filtering
    for (let val of userData) {
      if (packageType.includes(val.packageType)) {
        if (!result.includes(val)) {
          result.push(val);
        }
      }
    }

    //create list of items that include the filter tags
    const toFilterOut = [];

    filterList.length > 0 &&
      result.forEach((mailpiece) => {
        if (filterList.includes(mailpiece.status)) {
          if (!toFilterOut.includes(mailpiece)) {
            return toFilterOut.push(mailpiece);
          }
        }
      });

    //remove those that do not have the filter tags
    if (toFilterOut.length > 0) {
      result = result.filter((item) => toFilterOut.includes(item));
    }

    //if the search bar is active and there are results, filter out non-results
    if (searchInUse && searchItems.length > 0) {
      result = result.filter((item) => searchItems.includes(item));
    }

    //if no results, return empty
    else if (searchInUse && searchItems.length === 0) {
      result = [];
    }

    setRowCount(result.length);
    setFinalData(result);
  }, [userData, filterList, filteredItems.length, packageType, searchInUse, searchItems]);

  useEffect(() => {
    if (filterList.length > 0) {
      setShowFilterList(true);
    } else {
      setShowFilterList(false);
    }
  }, [showFilterList, filterList]);

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleViewableHistory = (data) => {
    viewAmount === 5 ? setViewAmount(data.history.length) : setViewAmount(5);
  };

  const sortByDate = (data) => {
    console.log("trigger");
    const sorted = data.sort((a, b) => {
      const dateA = moment(a["deliveryDate"]).utc().format("YYYY-MM-DD");
      const dateB = moment(b["deliveryDate"]).utc().format("YYYY-MM-DD");
      if (dateA !== dateB) {
        return dateA > dateB ? 1 : -1;
      }
      return dateA < dateB ? 1 : -1;
    });
    return sorted;
  };

  const handlePerRowsChange = (newPerPage) => {
    setRowsAmount(newPerPage);
  };

  const attentionRowStyles = [
    {
      when: (row) => row.attentionNeeded,
      style: () => ({
        backgroundColor: "rgb(244, 227, 219)",
      }),
    },
  ];

  const ActiveCheck = (valToCheck) => {
    for (let val of packageType) {
      if (val.includes(valToCheck)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Container>
      {!showPackageDetails && (
        <CurtainHeader>
          <span>Informed Delivery Business Dashboard</span>
        </CurtainHeader>
      )}

      {!showPackageDetails && (
        <>
          <WelcomeHeader greeting={"View your packages arriving soon."} />
          <SearchAndFilterWrapper>
            <SearchAndFilterContainer>
              <FilterButtonWrapper>
                <PackageFilterTypeContainer active={ActiveCheck("Inbound") && ActiveCheck("Outbound")}>
                  <PackageFilterType
                    onClick={() => {
                      setPackageType(["Inbound", "Outbound"]);
                      setCurrentPage(1);
                    }}
                  >
                    <div>
                      <b>{inboundCount + outboundCount}</b>
                    </div>
                    <div>All Packages</div>
                  </PackageFilterType>
                </PackageFilterTypeContainer>
                <PackageFilterTypeContainer active={ActiveCheck("Inbound") && !ActiveCheck("Outbound")}>
                  <PackageFilterType
                    onClick={() => {
                      setPackageType(["Inbound"]);
                      setCurrentPage(1);
                    }}
                  >
                    <div>
                      <b>{inboundCount}</b>
                    </div>
                    <div>Inbound</div>
                  </PackageFilterType>
                </PackageFilterTypeContainer>
                <PackageFilterTypeContainer active={ActiveCheck("Outbound") && !ActiveCheck("Inbound")}>
                  <PackageFilterType
                    onClick={() => {
                      setPackageType(["Outbound"]);
                      setCurrentPage(1);
                    }}
                  >
                    <div>
                      <b>{outboundCount}</b>
                    </div>
                    <div>Outbound</div>
                  </PackageFilterType>
                </PackageFilterTypeContainer>
              </FilterButtonWrapper>
              <SearchContainer>
                <Search
                  filteredItems={filteredItems}
                  packageType={packageType}
                  showFilterModal={showFilterModal}
                  setShowFilterModal={setShowFilterModal}
                  setSearchInUse={setSearchInUse}
                  setSearchItems={setSearchItems}
                  searchItems={searchItems}
                  filterList={filterList}
                  setFilteredItems={setFilteredItems}
                  setCurrentPage={setCurrentPage}
                  searchInUse={searchInUse}
                  userData={userData}
                />
              </SearchContainer>
            </SearchAndFilterContainer>

            {showFilterList && (
              <DesktopFilterListContainer>
                <FilterList
                  filterList={filterList}
                  setFilterList={setFilterList}
                  filterState={filterState}
                  setFilterState={setFilterState}
                  setPackageType={setPackageType}
                  filterInitialState={filterInitialState}
                  showFilterModal={showFilterModal}
                  setShowFilterModal={setShowFilterModal}
                />
              </DesktopFilterListContainer>
            )}
          </SearchAndFilterWrapper>

          {showFilterModal && (
            <FilterModalContainer>
              <CloseButton title="Close" onClick={() => setShowFilterModal(false)}>
                <CloseIcon />
              </CloseButton>
              <FilterSearchForm
                userData={userData}
                packageType={packageType}
                setPackageType={setPackageType}
                filterList={filterList}
                setFilterList={setFilterList}
                filterState={filterState}
                setFilterState={setFilterState}
                setShowFilterModal={setShowFilterModal}
                filterInitialState={filterInitialState}
              />
            </FilterModalContainer>
          )}
          {isLoading ? (
            <p>
              <Spinner />
            </p>
          ) : (
            <ContentContainer>
              <TableContainer>
                <DataTable
                  columns={[
                    {
                      cell: (row) => (row.attentionNeeded ? <LeftTab /> : ""),
                      maxWidth: "30px",
                      minWidth: "16px",
                    },
                    {
                      name: "Type",
                      cell: (row) => (
                        <DataContainer>
                          {row.type.toLowerCase().includes("inbound") ? "Inbound" : "Outbound"}
                        </DataContainer>
                      ),
                      maxWidth: "160px",
                    },
                    {
                      name: "Shipper Name",
                      cell: (row) => <DataContainer>{row.shipperName}</DataContainer>,
                      maxWidth: "330px",
                    },
                    {
                      name: "Expected Delivery",
                      cell: (row) => (
                        <DataContainer>
                          {row.deliveryDate ? moment(row.deliveryDate).format("MMMM Do") : "- -"}
                        </DataContainer>
                      ),
                      maxWidth: "254px",
                    },
                    {
                      name: "Tracking Number",
                      cell: (row) => (
                        <DataContainer>
                          <p>{row.trackingNumber}</p>
                        </DataContainer>
                      ),
                      maxWidth: "380px",
                    },
                    {
                      name: "Campaign Image",
                      cell: (row, id) => <RideAlongImage row={row} tippyId={id + 1} isLast={id + 1 === rowsAmount} />,
                      maxWidth: "140px",
                    },
                  ]}
                  data={finalData}
                  responsive
                  expandableRows
                  expandableRowDisabled={() => true}
                  // expandableRowExpanded={(row) => row === currentRow}
                  expandableRowExpanded={(row) => row.isExpanded}
                  expandableRowsComponent={ExpandedDetailsContent(viewAmount, handleViewableHistory)}
                  expandableRowsHideExpander
                  expandableDisabledField={true}
                  expandOnRowClicked
                  onRowExpandToggled={(expanded, row) => {
                    if (row === currentRow) {
                      setCurrentRow("");
                    } else {
                      setCurrentRow(row);
                    }
                    row.isExpanded = expanded;
                    row.isExpanded ? (row.rotateAmount = 180) : (row.rotateAmount = 0);
                  }}
                  conditionalRowStyles={attentionRowStyles}
                  noDataComponent={
                    finalData?.length === 0 && !searchInUse ? (
                      <NoDataComponent packageType={packageType} />
                    ) : (
                      <NoResultsComponent />
                    )
                  }
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationComponent={PaginationComponent}
                  paginationPerPage={rowsAmount}
                  paginationRowsPerPageOptions={[10, 20, 30, 40]}
                  onChangeRowsPerPage={handlePerRowsChange}
                  customStyles={{
                    headCells: {
                      style: {
                        paddingLeft: "0px",
                        backgroundColor: Theme.colors.gray[200],
                        fontWeight: "bold",
                        fontSize: "13px",
                      },
                    },
                    cells: {
                      style: {
                        paddingLeft: "0px",
                        fontSize: "16px",
                        height: "95px",
                        alignItems: "start",
                      },
                    },
                  }}
                />
              </TableContainer>

              {showFilterList && (
                <MobileFilterListContainer>
                  <FilterList
                    filterList={filterList}
                    setFilterList={setFilterList}
                    filterState={filterState}
                    setFilterState={setFilterState}
                    setPackageType={setPackageType}
                    filterInitialState={filterInitialState}
                  />
                </MobileFilterListContainer>
              )}
              <PackageListContainer>
                {finalData?.slice((currentPage - 1) * rowsAmount, rowsAmount * currentPage).map((item, i) => {
                  return (
                    <PackagesCard
                      key={`${item}-${i}`}
                      pkg={item}
                      index={finalData.indexOf(item) + 1}
                      count={finalData.length}
                      setShowPackageDetails={setShowPackageDetails}
                      setPackageToShow={setPackageToShow}
                    />
                  );
                })}
                {finalData?.length === 0 && !searchInUse && <NoDataComponent packageType={packageType} />}
                {finalData?.length === 0 && searchInUse && <NoResultsComponent />}
                <PackagesSecondaryPagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  count={rowCount}
                  rowsPerPage={rowsAmount}
                  page={currentPage - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationRowsPerPageOptions={paginationRowsPerPage}
                />
              </PackageListContainer>
            </ContentContainer>
          )}
        </>
      )}
      {showPackageDetails && (
        <PackageDetails
          onClose={() => setShowPackageDetails(false)}
          pkg={packageToShow?.packageInfo}
          index={packageToShow?.index}
          count={packageToShow?.count}
        />
      )}
    </Container>
  );
};

// TODO remove components from within packages?

PaginationComponent.propTypes = {
  rowsPerPage: PropTypes.number,
  rowCount: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setRowsAmount: PropTypes.func,
  paginationRowsPerPageOptions: PropTypes.array,
};

NoDataComponent.propTypes = {
  packageType: PropTypes.array,
};

// ExpandedDetailsContent.propTypes = {
//   data: PropTypes.object,
// };
