import React from "react";
import styled from "styled-components";
import { CaretIcon, Theme } from "@informed-innovations/components";
import moment from "moment";
import PropTypes from "prop-types";

const Card = styled.div`
  width: 100%;
  margin: auto 0;
  height: 179px;
  justify-content: space-around;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: 3px solid ${Theme.colors.cyan};
    transition: outline 0.15s ease;
    box-shadow: 0px 3px 12px ${Theme.colors.cyan};
  }
  @media screen and (max-width: 405px) {
    height: 230px;
  }
`;

const CardContainer = styled.div`
  margin: 20px 0;
  border-radius: 6px;
  border: 1px solid ${Theme.colors.gray[200]};
  border-radius: 5px;
`;

const CardInner = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  height: 100%;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 280px) {
    padding: 20px 5px;
  }
`;

const CardDate = styled.div`
  color: ${Theme.colors.black};
  margin-top: -4px;
  font-size: 22px;
  text-align: center;
  max-width: 50px;
  b {
    font-size: 33px;
  }
`;

const CardDetails = styled.div`
  padding: 0 10px;
  color: ${Theme.colors.black};
  font-weight: bold;
  h4 {
    color: ${Theme.colors.black};
  }
  min-width: 100px;
  max-width: 220px;

  @media screen and (max-width: 330px) {
    max-width: 120px;
    p {
      margin-bottom: 0;
    }
  }
`;

const TrackingDiv = styled.div`
  word-break: break-word;
`;

const CardButton = styled.div`
  min-width: 60px;
  max-width: 80px;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
  svg {
    margin-left: auto;
  }
`;

const StatusColor = styled.span`
  color: ${(props) => (props.attentionNeeded ? Theme.colors.red : Theme.colors.blue)};
`;

const FromDiv = styled.div`
  max-width: 220px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  text-transform: capitalize;
`;

export const PackagesCard = ({ pkg, index, count, setShowPackageDetails, setPackageToShow }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const estDelivery = moment(pkg.deliveryDate).format("MMMM Do").split(" ");
  const estDeliveryMonth = estDelivery.length > 0 ? estDelivery[0] : "";
  const estDeliveryDay = estDelivery[1];
  const from = pkg.shipperName;

  const packageStatus = () => {
    if (!pkg.status) return;
    if (pkg.attentionNeeded) {
      return (
        <StatusColor attentionNeeded={pkg.attentionNeeded}>
          {pkg.status}
          <br />
        </StatusColor>
      );
    } else {
      return <span>{pkg.status}</span>;
    }
  };

  return (
    <CardContainer key={pkg.packageID}>
      <Card>
        <CardInner>
          <CardDate>
            {estDeliveryMonth === "" && estDeliveryDay === "" ? (
              <div>
                DATE
                <br />
                <b>- -</b>
              </div>
            ) : (
              <div>
                {estDeliveryMonth}
                <br />
                <b>{estDeliveryDay}</b>
              </div>
            )}
          </CardDate>
          <CardDetails>
            <h4>{pkg.packageType}</h4>
            <p>{packageStatus()}</p>
            <TrackingDiv>{pkg.tracking}</TrackingDiv>
            <FromDiv>{from}</FromDiv>
          </CardDetails>
          <CardButton>
            {/* TODO check removal of div and adding onClick to CaretIcon still works*/}
            <CaretIcon
              onClick={() => {
                setPackageToShow({ packageInfo: pkg, index: index, count: count });
                setShowPackageDetails(true);
                scrollToTop();
              }}
              style={{
                width: "20px",
                height: "18px",
                transform: `rotate(-90deg)`,
                fill: `${Theme.colors.blue}`,
              }}
            />
            <div>
              {index} of {count}
            </div>
          </CardButton>
        </CardInner>
      </Card>
    </CardContainer>
  );
};

PackagesCard.propTypes = {
  pkg: PropTypes.object,
  index: PropTypes.number,
  count: PropTypes.number,
  setShowPackageDetails: PropTypes.func,
  setPackageToShow: PropTypes.func,
};
