import React, { useState } from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { DownCarrat } from "@informed-innovations/components";
import { AuthSecureImage } from "../../common/components/AuthSecureImage";
import PropTypes from "prop-types";

const Container = styled.div`
  max-width: 85%;
  margin: 0 auto;
  .carousel .slide {
    display: block;
    margin: auto 0;
    width: 100%;
  }
  .carousel-status {
    font-size: 16px;
    width: 100%;
    text-align: center;
    text-shadow: none;
    color: black;
    top: 92%;
    bottom: 0;

    @media screen and (max-width: 500px) {
      top: 88%;
    }
  }
`;

const Slide = styled.div`
  padding-bottom: 90px;
  max-width: 880px;
  text-align: left;
  position: relative;
  z-index: 0;

  img {
    max-width: 875px;
  }

  @media screen and (max-width: 500px) {
    padding-bottom: 50px;
  }
`;

const LeftArrow = styled.div`
  margin: auto;
  svg {
    &:hover {
      cursor: pointer;
    }
    transform: rotate(90deg) scale(2);
  }
  @media screen and (max-width: 960px) {
    margin-right: 40px;
  }

  @media screen and (max-width: 755px) {
    margin-bottom: 40%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 36%;
  }
`;

const RightArrow = styled.div`
  margin: auto;
  svg {
    &:hover {
      cursor: pointer;
    }
    transform: rotate(-90deg) scale(2);
  }

  @media screen and (max-width: 960px) {
    margin-left: 40px;
  }

  @media screen and (max-width: 755px) {
    margin-bottom: 40%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 36%;
  }
`;

const CardImageContainer = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  max-width: 60%;

  @media screen and (max-width: 425px) {
    width: 100%;
    max-height: 100%;
  }
`;

const MailImage = styled.img`
  max-width: 50%;
`;

const Status = styled.span`
  b {
    padding: 0 10px;
  }
`;

export const MailSlideshow = ({ data }) => {
  const [slideState, setSlideState] = useState(data.startingSlide);

  const next = () => {
    setSlideState(slideState + 1);
  };

  const prev = () => {
    setSlideState(slideState - 1);
  };

  const updatedCurrentSlide = (index) => {
    if (slideState !== index) {
      setSlideState(index);
    }
  };

  const generateImage = (item) => {
    const mailImage = item.image ? item.image.links[0].href : item.campaign.replacementImage.links[0].href;
    if (mailImage.includes("image-no-mailpiece")) {
      return <MailImage src={mailImage} alt={"alt"} />;
    } else {
      return <AuthSecureImage imageData={mailImage} component={MailImage} alt={"alt"} />;
    }
  };

  return (
    <>
      <LeftArrow>
        <DownCarrat onClick={() => prev()} />
      </LeftArrow>
      <Container>
        <Carousel
        // below statement is caught by sonarlint but is required syntax for component
          statusFormatter={(current, total) => (
            <Status>
              <b>{current}</b> of <b>{total}</b>
            </Status>
          )}
          showThumbs={false}
          showStatus={true}
          showArrows={false}
          selectedItem={slideState}
          autoPlay={false}
          interval={5000}
          infiniteLoop={true}
          onChange={updatedCurrentSlide}
          showIndicators={false}
        >
          {Object.values(data.data).map((item) => {
            if (item.image || item.campaign) {
              return (
                <Slide key={item.imb}>
                  <CardImageContainer>{generateImage(item)}</CardImageContainer>
                </Slide>
              );
            }
            return null;
          })}
        </Carousel>
      </Container>
      <RightArrow>
        <DownCarrat onClick={() => next()} />
      </RightArrow>
    </>
  );
};

MailSlideshow.propTypes = {
  data: PropTypes.object,
}
