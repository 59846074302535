export const packageInitialState = [
  {
    section: "TYPE",
    options: [
      {
        title: "Inbound",
        value: false,
      },
      {
        title: "Outbound",
        value: false,
      },
    ],
  },
];
export const filterInitialState = [
  {
    section: "EXPECTED DELIVERY",
    options: [
      {
        title: "Arriving Today",
        value: false,
      },
      {
        title: "Arriving Soon",
        value: false,
      },
    ],
  },
];
