import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Theme } from "@informed-innovations/components";
import { useNavigate } from "react-router-dom";
import { RefreshIcon } from "../../common/components/icons/RefreshIcon";
import { IncomingMailIcon } from "../../common/components/icons/IncomingMailIcon";
import { DashboardSlideshow } from "./DashboardSlideshow";
import { WelcomeHeader } from "../shared/WelcomeHeader";
import { usePostDashboardMutation } from "../../services/api.js";
import moment from "moment";
import { AttentionPackages } from "./AttentionPackages";
import PropTypes from "prop-types";
import { MAX_INBOUND_PACKAGES, MAX_MAIL_PIECES, MAX_OUTBOUND_PACKAGES } from "../../config/constants.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CurtainHeader = styled.div`
  display: none;
  height: 53px;
  background: ${Theme.colors.blue};
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${Theme.colors.red};

  span {
    color: white;
    font-size: 18px;
    text-align: center;
  }
  @media screen and (max-width: 960px) {
    display: flex;
  }
`;

const ContentContainer = styled.div`
  display: table;
  position: relative;
  margin: 0px auto 30px;
  padding: 20px;
  padding-bottom: 0;
  max-width: 1270px;
  width: 100%;
  z-index: 0;
  @media screen and (max-width: 960px) {
    margin: 0 auto;
    padding: 20px 0px;
  }
`;

const PackagesContainer = styled.div`
  display: table-cell;
  padding-right: 10px;
  width: 70%;
  h3 {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 960px) {
    display: grid;
    width: 100%;
    padding: 0;
    padding-top: 30px;
    h3 {
      margin-bottom: 0;
      text-align: center;
    }
    gap: 15px;
  }
`;

const MailContainer = styled.div`
  display: table-cell;
  width: 30%;
  h3 {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 960px) {
    display: grid;
    width: 100%;
    padding: 0;
    padding-top: 30px;
    h3 {
      margin-bottom: 0;
      text-align: center;
    }
    gap: 15px;
  }
`;

const PackagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  height: ${(props) => props.height};
  background-color: ${Theme.colors.gray[100]};
  a {
    text-decoration: none;
    color: ${Theme.colors.white};
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
    display: block;
    padding: 20px;
  }
`;

const MailWrapper = styled.div`
  padding: 20px 0;
  height: ${(props) => props.height};
  background-color: ${Theme.colors.gray[100]};
  a {
    text-decoration: none;
    color: ${Theme.colors.white};
    font-weight: bold;
  }
  @media screen and (max-width: 960px) {
    display: block;
    padding: 20px;
  }
`;

const MailInnerWrapper = styled.div`
  position: relative;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  h1 {
    display: inline-block;
  }

  @media screen and (max-width: 960px) {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  margin-left: -20px;
  bottom: 0;
  text-align: center;
  a {
    display: inline-block;
    min-width: 275px;
  }
  @media screen and (max-width: 960px) {
    position: static;
    margin-left: 0;
  }
  @media screen and (max-width: 360px) {
    button {
      min-width: 100%;
    }
  }
`;

const Separator = styled.div`
  border-right: 2px solid ${Theme.colors.gray[400]};
`;

const RefreshContainer = styled.div`
  margin: 0 auto;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-bottom: 20px;
  p {
    margin: 0;
  }
`;

const RefreshButton = styled(Button)`
  color: ${Theme.colors.indigo};
  svg {
    margin-right: 4px;
  }
`;

const SlideshowContainer = styled.div`
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
`;

const Mail = ({ count }) => {
  const navigate = useNavigate();
  const handleHistory = (to) => {
    navigate(`/${to}`);
  };

  return (
    <MailInnerWrapper>
      <IncomingMailIcon fill={Theme.colors.gray[400]} />
      <h4>Incoming Mail</h4>
      {count === 0 ? (
        <Wrapper>
          <p style={{ paddingTop: "48px" }}>You currently have no mail to display.</p>
          <h1>{count}</h1>
        </Wrapper>
      ) : (
        <span style={{ position: "absolute", bottom: "55px" }}>
          <h1>{count}</h1>
        </span>
      )}

      <ButtonWrapper>
        <Button primary onClick={() => handleHistory("mail")}>
          View All Mail
        </Button>
      </ButtonWrapper>
    </MailInnerWrapper>
  );
};

export const Dashboard = () => {
  const currentDate = moment().format("YYYY-MM-DD");

  const [getDashboard, getDashboardResponse] = usePostDashboardMutation();
  const [init, setInit] = useState(false);

  const [mailCount, setMailCount] = useState(0);
  const [inboundCount, setInboundCount] = useState(0);
  const [inboundDelivered /*setInboundDelivered*/] = useState(0);
  const [outboundCount, setOutboundCount] = useState(0);
  const [outboundDelivered /*setOutboundDelivered*/] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);
  const [refreshHover, setRefreshHover] = useState(false);
  const [showPackageDetails /*setShowPackageDetails*/] = useState(false);
  const [actionsNeeded /*setActionsNeeded*/] = useState(0);

  const handleRequest = () => {
    getDashboard({ date: currentDate });
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [refreshDate, setRefreshDate] = useState([
    new Date().getMonth(),
    new Date().getDate(),
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  ]);

  const handleRefresh = () => {
    setRefreshDate([
      new Date().getMonth(),
      new Date().getDate(),
      new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    ]);

    handleRequest();
  };

  const handleMouseEnter = () => {
    setRefreshHover(true);
  };

  const handleMouseLeave = () => {
    setRefreshHover(false);
  };

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (!init) {
      getDashboard({
        deliveryDate: currentDate,
        maxMailPieces: MAX_MAIL_PIECES,
        maxSaturation: 10,
        maxInboundPackages: MAX_INBOUND_PACKAGES,
        maxOutboundPackages: MAX_OUTBOUND_PACKAGES,
        campaigns: true,
      });
      setInit(true);
    }
  }, [getDashboard, init, setInit, currentDate]);

  // TODO anaylize new incoming data
  useEffect(() => {
    console.log(getDashboardResponse);
    if (getDashboardResponse.data) {
      setMailCount(getDashboardResponse.data.mailpieces?.length);
      if (getDashboardResponse.data.inboundPackages.length > 0) {
        setInboundCount(
          getDashboardResponse.data.inboundPackages?.arrivingToday.length +
            getDashboardResponse.data.inboundPackages?.arrivingSoon.length
        );
      }
      if (getDashboardResponse.data.outboundPackages.length > 0) {
        setOutboundCount(getDashboardResponse.data.outboundPackages?.length);
      }
    }
  }, [getDashboardResponse]);

  return (
    <Container>
      {!showPackageDetails && (
        <CurtainHeader>
          <span>Informed Delivery Business Dashboard</span>
        </CurtainHeader>
      )}
      {!showPackageDetails && (
        <>
          <WelcomeHeader greeting={"Here's an overview of your packages and mail expected soon."} />
          {isDesktop ? (
            <ContentContainer>
              <PackagesContainer>
                <h3>Packages Expected Soon</h3>
                <PackagesWrapper height={actionsNeeded > 0 ? "320px" : "257px"}>
                  <AttentionPackages
                    type="Inbound"
                    completed={inboundDelivered}
                    count={inboundCount}
                    linkTo="/dashboard"
                  />
                  <Separator />
                  <AttentionPackages
                    type="Outbound"
                    completed={outboundDelivered}
                    count={outboundCount}
                    linkTo="/dashboard"
                  />
                </PackagesWrapper>
              </PackagesContainer>
              <MailContainer>
                <h3>Mail Expected Soon</h3>
                <MailWrapper height={actionsNeeded > 0 ? "320px" : "257px"}>
                  <Mail count={mailCount} linkTo="/dashboard" />
                </MailWrapper>
              </MailContainer>
            </ContentContainer>
          ) : (
            <ContentContainer>
              <PackagesContainer>
                <h3>Packages Expected Soon</h3>
                <PackagesWrapper>
                  <AttentionPackages
                    type="Inbound"
                    completed={inboundDelivered}
                    count={inboundCount}
                    linkTo="/dashboard"
                  />
                </PackagesWrapper>
                <PackagesWrapper>
                  <AttentionPackages
                    type="Outbound"
                    completed={outboundDelivered}
                    count={outboundCount}
                    linkTo="/dashboard"
                  />
                </PackagesWrapper>
              </PackagesContainer>
              <MailContainer>
                <h3>Mail Expected Soon</h3>
                <MailWrapper height={actionsNeeded > 0 ? "320px" : "257px"}>
                  <Mail count={mailCount} linkTo="/dashboard" />
                </MailWrapper>
              </MailContainer>
            </ContentContainer>
          )}

          <RefreshContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <p>
              Last Updated {monthNames[refreshDate[0]]} {refreshDate[1]}, {refreshDate[2].toLowerCase()}
            </p>
            <RefreshButton text onClick={() => handleRefresh()}>
              <RefreshIcon fill={refreshHover ? Theme.colors.gray[700] : Theme.colors.indigo} />
              Refresh
            </RefreshButton>
          </RefreshContainer>

          <SlideshowContainer>
            <DashboardSlideshow type={"dashboard"} isDesktop={isDesktop} />
          </SlideshowContainer>
        </>
      )}
    </Container>
  );
};

Mail.propTypes = {
  count: PropTypes.number,
};
