import { CloseIcon, SearchIcon, SortIcon, Theme, UspsFormControl } from "@informed-innovations/components";
import React, { useRef } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const SearchInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchField = styled(UspsFormControl)`
  z-index: 20;
  font-size: 14px;
  color: ${Theme.colors.blue};
  ::placeholder {
    color: ${Theme.colors.gray[600]};
  }
  &:focus {
    color: ${Theme.colors.blue};
  }
`;

const InputIcon = styled(SearchIcon)`
  float: right;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 16px;
  cursor: pointer;
`;

const XIcon = styled(CloseIcon)`
  float: right;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 14px;
  right: 16px;
  cursor: pointer;
`;

const FilterButton = styled.button`
  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-align: center;
  color: ${Theme.colors.blue};
  font-weight: bold;
  font-size: 18px;
  &:after {
    content: "Filter";
  }

  @media only screen and (max-width: 960px) {
    z-index: 10;
    min-width: 25px;
    display: flex;
    margin-left: auto;
    padding-top: 16px;
    &:after {
      content: "Filter";
    }
    ${(props) =>
      props.visible &&
      css`
        display: none;
      `}

    ${(props) =>
      props.positioning &&
      css`
        position: absolute;
        right: 20px;
      `}
  }
`;
export const Search = ({
  showFilterModal,
  setShowFilterModal,
  setSearchInUse,
  setSearchItems,
  filterList,
  setFilteredItems,
  setCurrentPage,
  searchInUse,
  userData,
}) => {
  const ref = useRef(null);

  const onClear = () => {
    ref.current.value = "";
    setSearchItems([]);
    setSearchInUse(false);
  };

  return (
    <SearchWrapper>
      <SearchInputContainer>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          autoComplete="off"
          style={{ width: "100%" }}
        >
          <SearchField
            ref={ref}
            type="text"
            placeholder="Search All Packages"
            onChange={(e) => {
              const value = e.target.value.toLowerCase().trim();
              if (value) {
                setSearchInUse(true);
              }
              setCurrentPage(1);
              const filteredUserData = userData.filter((item) => {
                const shipperNameIncludes = item.shipperName.toLowerCase().includes(value);
                const trackingNumberIncludes = item.trackingNumber.toLowerCase().includes(value);
                const statusIncludes = item.status?.toLowerCase().includes(value);
                const typeIncludes = item.type.toLowerCase().includes(value);
                const dateIncludes = moment(item.deliveryDate).format("MMMM Do").toLowerCase().includes(value);
                return shipperNameIncludes || trackingNumberIncludes || statusIncludes || typeIncludes || dateIncludes;
              });
              if (filteredUserData) {
                setFilteredItems(filteredUserData);
                setSearchItems(filteredUserData);
              } else {
                setSearchInUse(false);
                setSearchItems([]);
              }
            }}
          />
        </form>
        {searchInUse ? <XIcon onClick={onClear} /> : <InputIcon fill={Theme.colors.blue} />}
      </SearchInputContainer>
      <FilterButton
        visible={showFilterModal}
        positioning={!!filterList.length > 0}
        onClick={() => setShowFilterModal(!showFilterModal)}
        title="Filter"
      >
        <SortIcon />
      </FilterButton>
    </SearchWrapper>
  );
};

Search.propTypes = {
  showFilterModal: PropTypes.bool,
  setShowFilterModal: PropTypes.func,
  setSearchInUse: PropTypes.func,
  setSearchItems: PropTypes.func,
  filterList: PropTypes.array,
  setFilteredItems: PropTypes.func,
  setCurrentPage: PropTypes.func,
  searchInUse: PropTypes.bool,
  userData: PropTypes.object,
};
