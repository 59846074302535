import { Theme } from "@informed-innovations/components";

export const MagnifyingGlassIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon-Services-Search"
      xmlns="http://www.w3.org/2000/svg"
      width="53"
      height="52.795"
      viewBox="0 0 53 52.795"
      fill={fill ? fill : Theme.colors.blue}
      {...props}
    >
      <path
        id="Icon-Services-Search-2"
        data-name="Icon-Services-Search"
        d="M998.47,4027.3l-12.5-15.264a22.327,22.327,0,0,0,.532-25.94c-.012-.018-.025-.035-.037-.052-.371-.537-.764-1.061-1.184-1.568-.028-.034-.059-.067-.088-.1-.412-.49-.844-.968-1.3-1.428-.013-.013-.024-.027-.037-.039a22.147,22.147,0,0,0-13.389-6.411c-.06-.006-.12-.016-.181-.022-.085-.009-.171-.011-.257-.019-.649-.056-1.3-.089-1.96-.089h0a22.33,22.33,0,0,0-22,18.529c-.028.164-.06.325-.084.488l0,.027q-.157,1.074-.212,2.17c-.018.37-.027.74-.027,1.115a22.335,22.335,0,0,0,22.329,22.324l.052,0c.53,0,1.055-.026,1.578-.063.114-.008.229-.014.343-.025q.783-.068,1.554-.19c.1-.015.192-.033.289-.05.534-.091,1.063-.2,1.585-.326l.009,0c.541-.134,1.074-.293,1.6-.467.07-.023.141-.045.21-.07q.785-.267,1.545-.591l.112-.05a22.07,22.07,0,0,0,3.043-1.613l.109-.069q.728-.468,1.424-.993l.01-.007,15.349,12.395a1.128,1.128,0,0,0,1.586-1.594Zm-42.749-16.256a17.462,17.462,0,0,1-1.923-22.406,17.558,17.558,0,0,1,1.918-2.3,17.466,17.466,0,0,1,24.707,0h0c.07.071.131.147.2.219.344.356.679.716.986,1.092.069.083.125.174.192.257.3.382.593.766.856,1.165.007.01.012.021.018.03a17.525,17.525,0,0,1-.016,19.2h0c-.271.411-.57.806-.879,1.2-.058.075-.109.154-.168.227-.315.385-.656.754-1.008,1.116-.063.063-.116.134-.18.2a17.35,17.35,0,0,1-8.918,4.781,17.668,17.668,0,0,1-3.435.336A17.349,17.349,0,0,1,955.721,4011.041Z"
        transform="translate(-945.741 -3976.363)"
        fill="#fff"
      />
      <g id="Icon-Add" transform="translate(14.848 15.25)">
        <path
          id="Icon-Add-2"
          data-name="Icon-Add"
          d="M707.462,989.063h-6.578v-7h-2v7h-6.576v2h6.576v6.155h2v-6.155h6.578Z"
          transform="translate(-692.308 -982.064)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
