import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { SESSION_KEY, CLIENT_ID } from "../config/constants";
import { useLocation } from "react-router-dom";

export const Logout = () => {
  const auth = useAuth();
  const location = useLocation();
  const broadcastChannel = new BroadcastChannel("auth_channel");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const isNoBroadcast = urlParams.get("nb") === "1";

    if (!isNoBroadcast) {
      console.log("No broadcast specified, so we are going to broadcast");
      broadcastChannel.postMessage({ type: "LOGOUT" });
      broadcastChannel.close();
    }
    localStorage.removeItem(SESSION_KEY.MILLIS);
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.origin,
      extraQueryParams: { client_id: CLIENT_ID },
    });
  });

  return null;
};
