import React from "react";
import { useGetSecureImageQuery } from "../../services/api";
import PropTypes from "prop-types";

export const AuthSecureImage = React.forwardRef(({ imageData, component, ...props }, ref) => {
  const { data } = useGetSecureImageQuery({ data: imageData });

  if (!data) return null;

  const Component = component;
  return <Component {...props} src={data} alt={props.alt} ref={ref} />;
});

AuthSecureImage.propTypes = {
  imageData: PropTypes.string,
  component: PropTypes.elementType,
}
