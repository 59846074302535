import { Theme } from "@informed-innovations/components";
import styled from "styled-components";
import Logo from "../../static/img/logo.svg";

const MainContainer = styled.div`
  background-color: ${Theme.colors.gray[100]};
  border-top: 1px solid ${Theme.colors.gray[400]};
`;

const Container = styled.div`
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media screen and (min-width: 992px) {
    max-width: 960px;
  }
  @media screen and (min-width: 1400px) {
    max-width: 1320px;
  }
`;

const Footer = styled(Container)`
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 0.75em;
`;

const LogoLink = styled.a`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  height: 27px;
  width: 220px;
  display: block;
  margin-top: 2.9em;
  margin-bottom: 2.9em;
`;

const Navigation = styled.nav`
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 630px) {
    justify-content: center;
  }
`;

const NavigationItem = styled.span`
  position: relative;
  text-align: center;
  align-self: center;
  a {
    color: ${Theme.colors.gray[700]};
    text-decoration: underline;
  }
  &:not(:last-child) {
    &::after {
      content: " ";
      border-right: 1px solid ${Theme.colors.gray[600]};
      transform: rotate(20deg);
      position: absolute;
      right: -8px;
      top: 0;
      height: 100%;
    }
  }
`;

const Copyright = styled.div`
  padding-top: 2.3em;
  padding-bottom: 2.3em;
  border-bottom: 1px solid ${Theme.colors.gray[400]};
`;

export const GlobalFooter = () => {
  return (
    <MainContainer>
      <Footer>
        <LogoLink href="https://www.usps.com/" title="USPS.com" />
        <Container>
          <Navigation>
            <NavigationItem>
              <a href="http://about.usps.com/who-we-are/privacy-policy/privacy-policy-highlights.htm">Privacy Policy</a>
            </NavigationItem>
            <NavigationItem>
              <a href="http://about.usps.com/termsofuse.htm">Terms of Use</a>
            </NavigationItem>
            <NavigationItem>
              <a href="http://about.usps.com/who-we-are/foia/welcome.htm">FOIA</a>
            </NavigationItem>
            <NavigationItem>
              <a href="http://about.usps.com/who-we-are/no-fear-act/welcome.htm">No FEAR/EEO Contacts</a>
            </NavigationItem>
          </Navigation>
        </Container>

        <Copyright>Copyright &copy; 2023 USPS. All Rights Reserved.</Copyright>
      </Footer>
    </MainContainer>
  );
};
