export const IncomingMailIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Envelopes"
      xmlns="http://www.w3.org/2000/svg"
      width="40.526"
      height="36.842"
      viewBox="0 0 40.526 36.842"
      {...props}
    >
      <path
        id="Path_3907"
        data-name="Path 3907"
        d="M48.855,13.193l-.767-4.233L44.334,9.6l.768,4.233Z"
        transform="translate(-17.121 -3.444)"
        fill={fill ? fill : "3573b1"}
      />
      <path
        id="Path_3908"
        data-name="Path 3908"
        d="M39.584,16.148l-2-.343L34.845.887A1.1,1.1,0,0,0,33.781,0H33.63L.941,5.617A1.152,1.152,0,0,0,.029,6.947L3.26,24.789l-.925,5.106a1.152,1.152,0,0,0,.912,1.33l32.688,5.617h.152a1.007,1.007,0,0,0,1.064-.887L40.5,17.478a1.152,1.152,0,0,0-.912-1.331M3.222,8.129,32.413,3.1l2.2,12.19-9.236-1.587-.291-1.557-4.406.749-3.62-.621,7.511-1.306-.322-1.746L12.124,11.334l.018.1-5.246-.9H6.744a1.007,1.007,0,0,0-1.064.887l-.9,4.943ZM34.567,33.737,5.376,28.711,8.113,13.635,37.3,18.661Z"
        transform="translate(0 0)"
        fill={fill ? fill : "3573b1"}
      />
      <path
        id="Path_3909"
        data-name="Path 3909"
        d="M37.26,33.812,25.106,31.7l-.323,1.752,12.155,2.112Z"
        transform="translate(-9.583 -12.241)"
        fill={fill ? fill : "3573b1"}
      />
      <path
        id="Path_3910"
        data-name="Path 3910"
        d="M36.35,38.9,24.187,36.836l-.315,1.752,12.164,2.066Z"
        transform="translate(-9.231 -14.224)"
        fill={fill ? fill : "3573b1"}
      />
      <path
        id="Path_3911"
        data-name="Path 3911"
        d="M35.42,44.065,23.266,41.952,22.943,43.7,35.1,45.816Z"
        transform="translate(-8.872 -16.2)"
        fill={fill ? fill : "3573b1"}
      />
      <path
        id="Path_3912"
        data-name="Path 3912"
        d="M54.044,32.4l-3.753-.643-.768,4.232,3.754.643Z"
        transform="translate(-19.141 -12.263)"
        fill={fill ? fill : "3573b1"}
      />
    </svg>
  );
};
