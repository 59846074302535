export const ArriveDepartPostOfficeIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon-Tools-Find-USPS-Locations"
      xmlns="http://www.w3.org/2000/svg"
      width="22.666"
      height="28.102"
      viewBox="0 0 22.666 28.102"
      {...props}
    >
      <path
        id="Icon-Tools-Find-USPS-Locations-2"
        data-name="Icon-Tools-Find-USPS-Locations"
        d="M540.368,3109.251a10.859,10.859,0,0,0-1.223-4.631,11.57,11.57,0,0,0-1.361-2.023,11.784,11.784,0,0,0-1.789-1.677,9.028,9.028,0,0,0-1.023-.69,8.271,8.271,0,0,0-1.1-.578,11.423,11.423,0,0,0-2.353-.789c-.4-.1-.82-.135-1.229-.2-.412-.039-.829-.049-1.244-.07l-.07,0c-.371.022-.716.028-1.109.061-.431.067-.888.113-1.294.21a11.5,11.5,0,0,0-2.354.789,8.222,8.222,0,0,0-1.095.578,8.888,8.888,0,0,0-1.025.69,11.828,11.828,0,0,0-1.79,1.676,11.576,11.576,0,0,0-1.358,2.023,10.217,10.217,0,0,0-.876,2.263,7.727,7.727,0,0,0-.243,1.188,8.273,8.273,0,0,0-.107,1.2l-.015.3c-.006.2,0,.206.006.32l.02.532c.038.39.07.838.141,1.215a18.5,18.5,0,0,0,3.894,8.243,31.2,31.2,0,0,0,3.02,3.332q.81.777,1.668,1.5c.582.489,1.143.942,1.8,1.414l.781.557.78-.557c.663-.472,1.224-.924,1.806-1.414s1.127-.985,1.667-1.5a31.157,31.157,0,0,0,3.019-3.332,18.48,18.48,0,0,0,3.893-8.243A11.457,11.457,0,0,0,540.368,3109.251Zm-3.058,2.015a15.559,15.559,0,0,1-3.273,6.9,27.552,27.552,0,0,1-2.688,2.984c-.486.469-.986.924-1.5,1.36-.259.221-.529.443-.8.656-.269-.213-.539-.435-.8-.656-.515-.436-1.017-.891-1.5-1.359a27.47,27.47,0,0,1-2.688-2.985,15.552,15.552,0,0,1-3.273-6.9c-.059-.315-.07-.57-.1-.878l-.019-.52,0-.129,0-.038,0-.038.014-.224a5.728,5.728,0,0,1,.082-.892,5.1,5.1,0,0,1,.181-.876,7.479,7.479,0,0,1,.651-1.668,7.886,7.886,0,0,1,2.336-2.727,6.157,6.157,0,0,1,.757-.508,5.493,5.493,0,0,1,.808-.42,8.22,8.22,0,0,1,1.731-.578c.3-.072.562-.087.843-.144.308-.026.664-.035,1-.052.3.019.6.025.9.048.3.054.608.076.905.148a8.193,8.193,0,0,1,1.732.578,5.574,5.574,0,0,1,.809.42,6.262,6.262,0,0,1,.757.508,8.9,8.9,0,0,1,1.329,1.234,8.8,8.8,0,0,1,1.008,1.493,7.525,7.525,0,0,1,.65,1.669,5.219,5.219,0,0,1,.176.837,5.989,5.989,0,0,1,.088.954A8.713,8.713,0,0,1,537.31,3111.267Zm-4.632-2.005a3.63,3.63,0,1,1-3.628-3.528A3.58,3.58,0,0,1,532.677,3109.261Z"
        transform="translate(-517.714 -3098.59)"
        fill={fill ? fill : "#3573b1"}
      />
    </svg>
  );
};
