export const OutForDeliveryIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon-Packaging-Pickup-Available"
      xmlns="http://www.w3.org/2000/svg"
      width="36.233"
      height="22.652"
      viewBox="0 0 36.233 22.652"
      {...props}
    >
      <path
        id="Icon-Packaging-Pickup-Available-2"
        data-name="Icon-Packaging-Pickup-Available"
        d="M668.293,4885.437a4.453,4.453,0,0,0-.2,2.213h-8.442a4.426,4.426,0,0,0-.2-2.213Zm12.743,1.134a1.082,1.082,0,0,1-.333.783,1.161,1.161,0,0,1-.809.326h-2.039a4.443,4.443,0,0,0-.18-2.209h1.072l-.014-3.316H648.684l-.379,3.283h1.781a4.443,4.443,0,0,0-.2,2.213H645.93a1.091,1.091,0,1,1,0-2.181H646l1.886-16.377a1.13,1.13,0,0,1,1.135-.983h20.433a1.193,1.193,0,0,1,.249.028,1.136,1.136,0,0,1,.931.631l3.536,7.375,6.34,4.153a1.094,1.094,0,0,1,.5.911Zm-11.8-10.171h2.538l-2.538-5.294Zm-20.179,2.487h9.948l.035-6.6a.6.6,0,0,1,.608-.584h0l6.561.013a.6.6,0,0,1,.606.587v6.589h7.468l-1.657-1.085a1.143,1.143,0,0,1-.251-.227h-3.746a.6.6,0,0,1-.607-.588v-6.668H650.047Zm11.194-6.015-.032,5.978H665.6v-5.967Zm17.228,8.107-1.4-.919H648.925l-.106.919Zm-.561,5.968a3.944,3.944,0,1,1-3.941-3.812A3.883,3.883,0,0,1,676.92,4886.947Zm-1.714,0a2.23,2.23,0,1,0-2.228,2.156A2.194,2.194,0,0,0,675.207,4886.947Zm-16.493,0a3.944,3.944,0,1,1-3.942-3.812A3.884,3.884,0,0,1,658.714,4886.947Zm-1.714,0a2.23,2.23,0,1,0-2.228,2.156A2.194,2.194,0,0,0,657,4886.947Z"
        transform="translate(-644.803 -4868.109)"
        fill={fill ? fill : "#3573b1"}
      />
    </svg>
  );
};
