import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme } from "@informed-innovations/components";
import { WelcomeHeader } from "../shared/WelcomeHeader";
import { MailList } from "./MailList";
import { MailModal } from "./MailModal";
// import { useWrappedLazyGetMailpiecesQuery } from "../../services/api";
import { usePostMailpiecesMutation } from "../../services/api";
import { Loading } from "../../common/Loading";
import moment from "moment";
import { MAX_MAIL_PIECES } from "../../config/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin: 0px auto 40px;
  padding: 20px;
  padding-top: 0;
  max-width: 1345px;
  width: 100%;
  z-index: 0;
`;

const DayOfTheWeekContainer = styled.div`
  display: block;
  padding-bottom: 10px;
  border-bottom: ${(props) => (props.active ? `6px solid ${Theme.colors.red}` : "6px solid transparent")};
  @media screen and (max-width: 960px) {
    border-bottom: transparent;
  }
`;

const DayOfTheWeek = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 70px;
  background-color: ${Theme.colors.white};
  border: 2px solid ${Theme.colors.gray[400]};
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: ${Theme.colors.blue};
  text-align: right;
  padding: 18px;
  margin-top: 40px;

  b {
    color: ${Theme.colors.black};
    font-size: 18px;
    margin-left: -5px;
    margin-right: 10px;
  }

  &:hover {
    background-color: ${Theme.colors.gray[200]};
  }

  @media screen and (max-width: 960px) {
    border: none;
    padding: 0;
    width: 170px;
    height: 40px;
    b {
      margin-left: 0;
    }
    &:hover {
      background-color: transparent;
    }
  }
`;

const DayOfTheWeekWrapper = styled.span`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const InfoWrapper = styled.span`
  @media screen and (max-width: 960px) {
    border-bottom: ${(props) => (props.active ? `6px solid ${Theme.colors.red}` : "6px solid transparent")};

    padding-bottom: 4px;
  }
`;

export const Mail = () => {
  const [allMail, setAllMail] = useState([]);
  const [index, setIndex] = useState(0);

  const [getMail0, response0] = usePostMailpiecesMutation();
  const [getMail1, response1] = usePostMailpiecesMutation();
  const [getMail2, response2] = usePostMailpiecesMutation();
  const [getMail3, response3] = usePostMailpiecesMutation();
  const [getMail4, response4] = usePostMailpiecesMutation();
  const [getMail5, response5] = usePostMailpiecesMutation();

  useEffect(() => {
    if (allMail.length === 0) {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        let day = moment().subtract(i, "days");
        if (day.format("dddd") !== "Sunday") {
          let dayString = "";
          if (i === 0) {
            dayString = `Today, ${day.format("MMM DD")}`;
          } else if (i === 1) {
            dayString = `Yesterday, ${day.format("MMM DD")}`;
          } else {
            dayString = day.format("dddd, MMM DD");
          }
          dates.push({
            text: dayString,
            full: day.format("YYYY-MM-DD"),
            count: 0,
            mailpieces: [],
          });
        }
      }

      setAllMail(dates);

      getMail0({ deliveryDate: dates[0].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
      getMail1({ deliveryDate: dates[1].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
      getMail2({ deliveryDate: dates[2].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
      getMail3({ deliveryDate: dates[3].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
      getMail4({ deliveryDate: dates[4].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
      getMail5({ deliveryDate: dates[5].full, maxMailPieces: MAX_MAIL_PIECES, campaigns: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMail]);

  useEffect(() => {
    if (response0.data) {
      console.log(response0);
      allMail[0].mailpieces = response0.data.mailpieces;
      allMail[0].count = response0.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response0.data]);

  useEffect(() => {
    if (response1.data) {
      allMail[1].mailpieces = response1.data.mailpieces;
      allMail[1].count = response1.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response1.data]);

  useEffect(() => {
    if (response2.data) {
      allMail[2].mailpieces = response2.data.mailpieces;
      allMail[2].count = response2.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response2.data]);

  useEffect(() => {
    if (response3.data) {
      allMail[3].mailpieces = response3.data.mailpieces;
      allMail[3].count = response3.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response3.data]);

  useEffect(() => {
    if (response4.data) {
      allMail[4].mailpieces = response4.data.mailpieces;
      allMail[4].count = response4.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response4.data]);

  useEffect(() => {
    if (response5.data) {
      allMail[5].mailpieces = response5.data.mailpieces;
      allMail[5].count = response5.data.mailpieces.length;
      setAllMail([...allMail]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response5.data]);

  useEffect(() => {}, [allMail]);

  const [showMailModal, setShowMailModal] = useState(false);
  const [mailModalData, setMailModalData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleActiveChange = (index) => {
    setIndex(index);
    setCurrentPage(1);
    setRowsPerPage(10);
  };

  return (
    <Container>
      <WelcomeHeader greeting={"View your inbound mail arriving soon."} />
      <ContentContainer>
        <DayOfTheWeekWrapper>
          {allMail.length > 0 &&
            allMail.map((item, position) => {
              return (
                <DayOfTheWeekContainer
                  active={index === position}
                  key={`${item.text}-${position}`}
                  onClick={() => handleActiveChange(position)}
                >
                  <DayOfTheWeek>
                    <InfoWrapper active={index === position}>
                      <b>{item.count}</b>
                      {item.text}
                    </InfoWrapper>
                  </DayOfTheWeek>
                </DayOfTheWeekContainer>
              );
            })}
        </DayOfTheWeekWrapper>
        {allMail.length > 0 ? (
          <MailList
            userData={allMail[index]}
            showMailModal={showMailModal}
            setShowMailModal={setShowMailModal}
            setMailModalData={setMailModalData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <Loading />
        )}
      </ContentContainer>
      {showMailModal && <MailModal setShowMailModal={setShowMailModal} data={mailModalData} />}
    </Container>
  );
};
