import { Theme } from "@informed-innovations/components";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  width: 630px;
  margin: auto 0;
  height: ${(props) => props.height};
  justify-content: space-around;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: 3px solid ${Theme.colors.cyan};
    transition: outline 0.15s ease;
    box-shadow: 0px 3px 12px ${Theme.colors.cyan};
  }
  @media screen and (max-width: 1280px) {
    width: 500px;
  }
  @media screen and (max-width: 520px) {
    width: 460px;
  }

  @media screen and (max-width: 460px) {
    width: 300px;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 300px;
  @media screen and (max-width: 520px) {
    min-height: 272px;
  }

  @media screen and (max-width: 460px) {
    min-height: 200px;
  }
`;

const Card = ({ children, height }) => (
  <Container tabIndex={0} height={height}>
    <Content>{children}</Content>
  </Container>
);

Card.propTypes = {
  children: PropTypes.any,
  height: PropTypes.number,
};

export default Card;
