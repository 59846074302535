export const OutgoingPackagesIcon = ({ fill, ...props }) => {
  return (
    <svg
      id="Icon-Outbound"
      xmlns="http://www.w3.org/2000/svg"
      width="35.499"
      height="38.008"
      viewBox="0 0 35.499 38.008"
      {...props}
    >
      <path
        id="Icon-Outbound-2"
        data-name="Icon-Outbound"
        d="M3.544,31.553V9.15h8.017v4.358a.983.983,0,0,0,.988.98h7.088a1.067,1.067,0,0,0,.988-1V9.15h8.251v6.1a13.37,13.37,0,0,1,3.544,1.759V8.091a1.9,1.9,0,0,0-.465-1.232L26.145.585A1.859,1.859,0,0,0,24.867,0H7.553A1.658,1.658,0,0,0,6.275.585L.465,6.863A1.9,1.9,0,0,0,0,8.094V33.313a1.818,1.818,0,0,0,1.8,1.818H14.932a12.337,12.337,0,0,1-1.859-3.578Zm14-27.8.813,3.4h-4.59l.814-3.4Zm-4.067,5.4H18.65v3.341H13.479ZM27.342,7.156H20.31l-.843-3.519h4.587ZM8.309,3.637h4.35l-.843,3.519H5.061ZM32.477,20.06a10.379,10.379,0,0,0-14.757,0A10.718,10.718,0,0,0,14.7,27.509a10.369,10.369,0,0,0,3.022,7.449,10.516,10.516,0,0,0,7.379,3.05h.167A10.368,10.368,0,0,0,35.5,27.509a10.474,10.474,0,0,0-3.021-7.449M25.2,35.211a7.811,7.811,0,0,1-.1-15.621h0a7.772,7.772,0,0,1,7.786,7.76,7.776,7.776,0,0,1-7.688,7.86m5.3-9.179a.206.206,0,0,1,.069.192.185.185,0,0,1-.121.157l-1.121.452-.379.156-.4.157-1.242.505-.432.173-.569.227a.181.181,0,0,1-.207-.035.354.354,0,0,1-.069-.1v-.1l.1-.523.086-.47.017-.087h-.086a4.66,4.66,0,0,0-2.174.418l-.414.209a5.739,5.739,0,0,0-1.5,1.167,3.676,3.676,0,0,0-.327.4,4.04,4.04,0,0,0-.742,1.585,3.093,3.093,0,0,0-.086.7,4.4,4.4,0,0,0,.052.662c0,.018.017.053,0,.069a5.737,5.737,0,0,1-.293-2.316,5.33,5.33,0,0,1,.639-2.142c.121-.227.259-.436.4-.645a6.8,6.8,0,0,1,.862-.993c.1-.1.223-.209.344-.314a4.9,4.9,0,0,1,.776-.557c.19-.1.363-.21.552-.3.224-.1.448-.192.673-.279a4.618,4.618,0,0,1,1.054-.244,3.936,3.936,0,0,1,.776-.018l.035-.1.172-.941a.236.236,0,0,1,.085-.14c.017-.017.035-.034.052-.034a.182.182,0,0,1,.206.035Z"
        transform="translate(0 0)"
        fill={fill ? fill : "3573b1"}
      />
    </svg>
  );
};
