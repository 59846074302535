import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthorizationHeader } from "./apiHelpers";
import { AUTH_URL } from "../config/constants";

export const pingApi = createApi({
  reducerPath: "pingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_URL + "/session",
    prepareHeaders: (headers) => {
      headers.set("authorization", getAuthorizationHeader());
      return headers;
    },
  }),
  tagTypes: ["ping"],
  endpoints: (builder) => ({
    getPing: builder.query({
      query: () => "/ping",
      providesTags: ["ping"],
    }),
  }),
});

export const { useGetPingQuery } = pingApi;
