import { Select, Theme } from "@informed-innovations/components";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  color: ${Theme.colors.blue};
  b {
    margin: auto 0;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 70px;
    padding-top: 0;
  }
`;

const StyledSelect = styled(Select)`
  width: 80px;
  padding-right: 30px;
  margin-left: 20px;
  option {
    font-family: "Helvetica Neue Regular", Helvetica, Arial, sans-serif;
    color: ${Theme.colors.blue};
  }
  padding-left: 10px;
  @media screen and (max-width: 960px) {
    width: 50%;
  }
`;

export const RowsDropdown = ({ onSelect, ...props }) => {
  return (
    <Container>
      <b>Results Per Page:</b>
      <StyledSelect
        onChange={(evt) => {
          const split = evt.target.value.split(".");
          const value = split[0];
          onSelect(value);
        }}
      >
        {props.children}
      </StyledSelect>
    </Container>
  );
};

export const Option = ({ value, sortDir, children }) => {
  return <option value={`${value}.${sortDir}`}>{children}</option>;
};

RowsDropdown.propTypes = {
  onSelect: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Option.propTypes = {
  value: PropTypes.number,
  sortDir: PropTypes.string,
  children: PropTypes.any,
};
