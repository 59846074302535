export const NoImageIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55.677" height="50.972" viewBox="0 0 55.677 50.972">
      <g id="Group_7035" data-name="Group 7035" transform="translate(1.338 1.485)">
        <g id="Icon-Camera" transform="translate(2 5.5)">
          <path
            id="Icon-Camera-2"
            data-name="Icon-Camera"
            d="M23.99,14.138a6.256,6.256,0,1,0,6.2,6.256,6.237,6.237,0,0,0-6.2-6.256m0,9.607h0a3.352,3.352,0,1,1,3.324-3.352,3.342,3.342,0,0,1-3.324,3.352m18.171-19.2H35.255A5.838,5.838,0,0,0,29.538,0H17.618a5.837,5.837,0,0,0-5.711,4.575H5.839A5.815,5.815,0,0,0,0,10.352V31.481a5.815,5.815,0,0,0,5.82,5.746H42.162A5.812,5.812,0,0,0,48,31.451V10.323a5.812,5.812,0,0,0-5.839-5.775m1.287,26.935a1.28,1.28,0,0,1-1.287,1.273H5.82a1.281,1.281,0,0,1-1.286-1.273V10.326A1.279,1.279,0,0,1,5.82,9.053H16.334v-3.3A1.279,1.279,0,0,1,17.62,4.479h11.92a1.28,1.28,0,0,1,1.287,1.272v3.3H42.161a1.28,1.28,0,0,1,1.287,1.273ZM23.99,9.576A10.818,10.818,0,1,0,34.72,20.394,10.774,10.774,0,0,0,23.99,9.576m0,18.733A7.912,7.912,0,1,1,31.838,20.4a7.891,7.891,0,0,1-7.848,7.912"
            transform="translate(0 0)"
            fill={fill ? fill : "#336"}
          />
        </g>
        <g id="Group_7034" data-name="Group 7034">
          <line
            id="Line_781"
            data-name="Line 781"
            x2="50"
            y2="45"
            transform="translate(0 3)"
            fill="none"
            stroke="#336"
            strokeWidth="4"
          />
          <line
            id="Line_782"
            data-name="Line 782"
            x2="51"
            y2="46"
            transform="translate(2)"
            fill="none"
            stroke="#f7f7f7"
            strokeWidth="4"
          />
        </g>
      </g>
    </svg>
  );
};
