import { Theme } from "@informed-innovations/components";
import { useState } from "react";
import styled, {css} from "styled-components";
import { DesignCard } from "./DesignCard";
import { IncomingMailIcon } from "../../common/components/icons/IncomingMailIcon";
import { MailPagination } from "./MailPagination";
import PropTypes from "prop-types";

const Container = styled.div`
  width: 100%;
  padding: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  gap: 40px;
  margin-top: 20px;
  color: ${Theme.colors.gray[700]};
  font-size: 16px;

  ${(props) => 
    props.noMail && 
    css`
      display: flex;
      gap: 0;
      grid-gap: 0;
      grid-template-columns: none;
    `}

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 1020px) {
    flex-direction: column;
    flex-wrap: none;
    line-height: 2;
    justify-content: center;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;

const ComponentContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px 0;
  margin: 0 auto;
  p {
    color: ${Theme.colors.black};
  }
  h4 {
    color: ${Theme.colors.gray[700]};
    font-size: 18px;
    margin-bottom: 30px;
  }
  svg {
    margin-top: 16px;
    transform: scale(2);
  }
`;

export const MailList = ({
  userData,
  setShowMailModal,
  setMailModalData,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const paginationRowsPerPage = [10, 20, 30, 40];
  const [rowCount, setRowCount] = useState("");
  const [noMail, setNoMail] = useState(false);

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setRowsPerPage(newPerPage);
  };

  const handleImageClick = (newData, newStart) => {
    const arr = [];
    for (const item of newData) {
      if (item.mailpieceID) {
        arr.push({
          ...item,
        });
      }
    }
    const updatedValue = {
      data: arr,
      startingSlide: newStart,
    };
    setMailModalData((prev) => ({
      ...prev,
      ...updatedValue,
    }));
  };

  const newRenderedMail = () => {
    const count = userData.count;

    if (rowCount !== count) {
      setRowCount(count);
    }
    let imageMissing = false;
    if (count > 0) {
      if(noMail)
      setNoMail(false)
      const mailpieces = userData.mailpieces;
      return mailpieces.slice((currentPage - 1) * rowsPerPage, rowsPerPage * currentPage).map((digest) => {
        //this is a check to only add one 'Mailpiece without image' card at the very end of the list
        const mailImageCheck = digest.image
          ? digest.image.links[0].href
          : digest.campaign.replacementImage.links[0].href;
        if (imageMissing === false) {
          if (mailImageCheck.includes("image-no-mailpiece")) {
            imageMissing = true;
            // TODO: check revision works when site is up
            return <DesignCard key={`mail-${digest.serialNumber}`} mail={digest} imageMissing={false} />;
          }
        } else {
          return null;
        }
        return (
          <DesignCard
            key={`mail-${digest.serialNumber}`}
            mail={digest}
            setShowMailModal={setShowMailModal}
            onImageClick={() => handleImageClick(mailpieces, mailpieces.indexOf(digest))}
            imageMissing={imageMissing}
          />
        );
      });
    } else {
      if (!noMail){
        setNoMail(true)
      }
      return (
        <ComponentContainer>
          <h4>No mail is available to display, please check back tomorrow.</h4>
          <IncomingMailIcon fill={Theme.colors.gray[400]} />
        </ComponentContainer>
      );
    }
  };
  return (
    <>
      <Container noMail={noMail}>{userData && newRenderedMail()}</Container>
      {userData.count > 10 && (
        <MailPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onChangePage={onChangePage}
          setRowsPerPage={setRowsPerPage}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationRowsPerPageOptions={paginationRowsPerPage}
        />
      )}
    </>
  );
};

MailList.propTypes = {
  userData: PropTypes.object,
  setShowMailModal: PropTypes.func,
  setMailModalData: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
};
