const headerHtml = `

<link href="https://www.usps.com/global-elements/header/css/megamenu-c360.css" type="text/css" rel="stylesheet">

<style>

 html,body{min-width:0!important;}

 a{text-decoration:none;}

 

/* */

@media (min-width: 958px){

 .global--navigation~.g-alert, .g-alert~.g-alert, .g-alert {

     margin-bottom: 20px;

     margin-top: 0;

 

 }

 #utility-header {

     height: 30px;

 }

 .hidden-galert {

    position: absolute;

     left: -10000px;

     top: auto;

     width: 1px;

     height: 1px;

     overflow: hidden;

 }

 div#g-navigation {

     margin-bottom: 40px;

 }

}

@media (max-width: 958px) {

 .g-alert p br { display:none;}

 .global--navigation .mobile-header {margin-bottom: 20px;}  

}

 

 

.mobile-help {

    font-size: 16px;

     color:#333366;

   position:absolute;

     top:20px;

   left: 5%;

   min-width:150px;

}

.mobile-help:before {

   content: "";

   display: inline-block;

   height: 10px;

   width: 10px;

   background-image: url(https://www.usps.com/assets/images/home/text-link-carat-red.svg);

   background-repeat: no-repeat;

   margin-left: 0px;

   top: 0px;

   position: relative;

   left: -5px;

   -webkit-transform: rotate(180deg);

   -moz-transition: rotate(180deg);

   -ms-transform: rotate(180deg);

   transform: rotate(180deg);

}

 

/* Respacing for changes to megamenu */

@media only screen and (min-width:958px) {

   .global--navigation nav li.menuheader:nth-of-type(1) {

       width: 15%

   }

   .global--navigation nav li.menuheader:nth-of-type(2) {

       width: 11%

   }

   .global--navigation nav li.menuheader:nth-of-type(3) {

       width: 11%!important

   }

   .global--navigation nav li.menuheader:nth-of-type(4) {

       width: 13%

   }

   .global--navigation nav li.menuheader:nth-of-type(5) {

       width: 15%

   }

   .global--navigation nav li.menuheader:nth-of-type(6) {

       width: 16%

   }

   .global--navigation nav li.menuheader:nth-of-type(7) {

       width: 12%

   }

   .global--navigation nav li.menuheader:nth-of-type(8) {

       width: 4%

   }

}

@media (min-width:959px) and (max-width:1100px) {

   .global--navigation nav li.menuheader:nth-of-type(1) {

       width: 15%

   }

   .global--navigation nav li.menuheader:nth-of-type(2) {

       width: 12%

   }

   .global--navigation nav li.menuheader:nth-of-type(3) {

       width: 12%

   }

   .global--navigation nav li.menuheader:nth-of-type(4) {

       width: 12%

   }

   .global--navigation nav li.menuheader:nth-of-type(5) {

       width: 12%

   }

   .global--navigation nav li.menuheader:nth-of-type(6) {

       width: 15%

   }

   .global--navigation nav li.menuheader:nth-of-type(7) {

       width: 10%

   }

   .global--navigation nav li.menuheader:nth-of-type(8) {

       width: 3%

   }

}

/* end respacing for megamenu*/

 

/* adding the Print Customs Forms image */

@media only screen and (min-width: 959px) {

.global--navigation nav .tool-international-forms a:before, .global--navigation nav .tool-international-forms a:hover:before, .global--navigation nav .tool-international-forms a:focus:before {

   background: url(https://www.usps.com/assets/images/home/printcustomsforms.svg);

}

}

 

/* end adding the Print Customs Forms image */

 

/* INFORMED GROUP HEADER */

 

</style>

<script>var appID = "INFORMEDGROUP";</script>

<div class="nav-utility" id="nav-utility">

   <div class="utility-links" id="utility-header">

   <a tabindex="-1" href="https://www.usps.com/globals/site-index.htm" class="hidden-skip">Go to USPS.com Site Index.</a>

   <a tabindex="0" id="skiptomain" href="#endnav" class="hidden-skip keyboard">Skip to Main Content</a>

   <a tabindex="-1" name="skiputil" id="skiputil" href="#skipallnav" class="hidden-skip">Skip All Utility Navigation</a>

 

       <a id="link-locator" href="https://tools.usps.com/locations/">Locations</a>

       <a id="link-customer" href="https://www.usps.com/help/contact-us.htm">Support</a>

       <a id="link-myusps" href="https://informeddelivery.usps.com">Informed Delivery</a>

   </div>

</div>

<div class="global--navigation" id="g-navigation">

   <a tabindex="0" name="skipallnav" id="skipallnav" href="#endnav" class="hidden-skip keyboard">Skip to Main Content</a>

<div class="nav-full">

 

 <a class="global-logo" href="https://www.usps.com/">

   <img src="https://www.usps.com/global-elements/header/images/utility-header/logo-sb.svg" alt="Image of USPS.com logo." aria-label="Image of USPS.com logo." />

 </a>

   <div class="mobile-header">

       <a class="mobile-help" style="display: none;" href="https://faq.usps.com/s/">USPS.com Help</a>

       <a class="mobile-logo"  href="https://www.usps.com/"><img src="https://www.usps.com/assets/images/home/logo_mobile.svg" alt="USPS mobile logo"></a>

       <a class="mobile-search"  href="https://www.usps.com/search/results.htm?keyword=Contact%20Us"><img src="https://www.usps.com/assets/images/home/search.svg" alt="Search Icon"></a>

   </div>

 

 <nav>

     <div class="mobile-log-state">

       <div id="msign" class="mobile-utility">

           <div class="mobile-sign">

               <a href="https://reg.usps.com/entreg/LoginAction_input?app=Phoenix&amp;appURL=">Sign In</a>

           </div>

       </div>

   </div>

   <ul class='nav-list' role="menubar">

     <li class="qt-nav menuheader">

         <a tabindex="-1" name="navquicktools" id="navquicktools" href="#navmailship" class="hidden-skip">Skip Quick Tools Links</a>

       <a aria-expanded="false" role="menuitem" tabindex="0" aria-haspopup="true" class="nav-first-element menuitem" href="#">Quick Tools</a>

       <div class="">

           <ul role="menu" aria-hidden="true">

               <li>

                   <a role="menuitem" tabindex="-1" href="https://tools.usps.com/go/TrackConfirmAction_input">    

                       <img src="https://www.usps.com/assets/images/home/tracking.svg" alt="Tracking Icon">

                       <p>Track a Package</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://informeddelivery.usps.com/">

                       <img src="https://www.usps.com/global-elements/header/images/utility-header/mailman.svg" alt="Informed Delivery Icon">

                       <p>Informed Delivery</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://tools.usps.com/locations/">

                       <img src="https://www.usps.com/assets/images/home/location.svg" alt="Post Office Locator Icon">

                       <p>Find USPS Locations</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://store.usps.com/store/stamps">

                       <img src="https://www.usps.com/assets/images/home/stamps.svg" alt="Stamps Icon">

                       <p>Buy Stamps</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://tools.usps.com/schedule-pickup-steps.htm">

                       <img src="https://www.usps.com/assets/images/home/schedule_pickup.svg" alt="Schedule a Pickup Icon">

                       <p>Schedule a Pickup</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://postcalc.usps.com/">

                       <img src="https://www.usps.com/assets/images/home/calculate_price.svg" alt="Calculate a Price Icon">

                       <p>Calculate a Price</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://tools.usps.com/zip-code-lookup.htm">

                       <img src="https://www.usps.com/assets/images/home/find_zip.svg" alt="Zip Code™ Lookup Icon">

                       <p>Look Up a <br>ZIP Code<sup>&trade;</sup></p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://holdmail.usps.com/holdmail/">

                       <img src="https://www.usps.com/assets/images/home/holdmail.svg" alt="Holdmail Icon">

                       <p>Hold Mail</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://moversguide.usps.com/?referral=MG82">

                       <img src="https://www.usps.com/assets/images/home/change_address.svg" alt="Change of Address Icon">

                       <p>Change My Address</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/po-boxes.htm">

                       <img src="https://www.usps.com/assets/images/home/po_box.svg" alt="Post Office Boxes Icon">

                       <p>Rent/Renew a <br>PO Box</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://store.usps.com/store/results/free-shipping-supplies/shipping-supplies/_/N-alnx4jZ7d0v8v">

                       <img src="https://www.usps.com/assets/images/home/free_boxes.svg" alt="Shipping Supplies Icon">

                       <p>Free Boxes</p>

                   </a>

               </li>

               <li>

                   <a role="menuitem" tabindex="-1" href="https://cns.usps.com/">

                       <img src="https://www.usps.com/assets/images/home/featured_clicknship.svg" alt="Click-N-Ship Icon">

                       <p>Click-N-Ship</p>

                   </a>

               </li>

           </ul>

       </div>

     </li>

     <li  class="menuheader" >

         <a tabindex="-1" name="navmailship" id="navmailship" href="#navtrackmanage" class="hidden-skip">Skip Send Links</a>

       <a id="mail-ship-width" aria-expanded="false" class="menuitem" role="menuitem" tabindex="0" aria-haspopup="true" href="https://www.usps.com/ship/">Send</a>

       <div class="repos">

         <ul role="menu" aria-hidden="true" class="tools">

           <h3>Tools</h3>

           <li class="tool-cns"><a role="menuitem" tabindex="-1"  href="https://cns.usps.com/">Click-N-Ship</a></li>

           <li class="tool-stamps"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/">Stamps &amp; Supplies</a></li>

           <li class="tool-zip"><a role="menuitem" tabindex="-1"  href="https://tools.usps.com/zip-code-lookup.htm">Look Up a ZIP Code<sup>&trade;</sup></a></li>    

           <li class="tool-calc"><a role="menuitem" tabindex="-1"  href="https://postcalc.usps.com/">Calculate a Price</a></li>

           <li class="tool-pick"><a role="menuitem" tabindex="-1"  href="https://tools.usps.com/schedule-pickup-steps.htm">Schedule a Pickup</a></li>

           <li class="tool-find"><a role="menuitem" tabindex="-1"  href="https://tools.usps.com/locations/">Find USPS Locations</a></li>

           <li class="tool-track"><a role="menuitem" tabindex="-1" href="https://tools.usps.com/go/TrackConfirmAction_input">Tracking</a></li>  

         </ul>

         <ul role="menu" aria-hidden="true">

           <h3>Learn About</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/">Sending</a></li>

               <ul>

                   <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/letters.htm">Sending Mail</a></li>

                   <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/packages.htm">Sending Packages</a></li>

                   <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/insurance-extra-services.htm">Insurance &amp; Extra Services</a></li>

                   <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/shipping-restrictions.htm">Shipping Restrictions</a></li>      

               </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/online-shipping.htm">Online Shipping</a></li>

               <ul>

                   <li><a role="menuitem" tabindex="-1" href="https://www.uspssmartpackagelockers.com/package-shippers">Ship to USPS Smart Locker</a></li>

                   <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/label-broker.htm">Label Broker</a></li>  

               </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/custom-mail.htm">Custom Mail, Cards, &amp; Envelopes</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/prices.htm">Postage Prices</a></li>    

         </ul>

         <ul role="menu" aria-hidden="true">

           <h3 class="desktop-only">&nbsp;</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/mail-shipping-services.htm">Mail &amp; Shipping Services</a></li>

               <ul>

                 <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/priority-mail-express.htm">Priority Mail Express</a></li>

                 <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/priority-mail.htm">Priority Mail</a></li>

                 <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/ground-advantage.htm">USPS Ground Advantage</a></li>          

                 <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/first-class-mail.htm">First-Class Mail</a></li>

                 <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/apo-fpo-dpo.htm">Military &amp; Diplomatic Mail</a></li>

              </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/package-intercept.htm">Redirecting a Package</a></li>        

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/shop/money-orders.htm">Money Orders</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/claims.htm">Filing a Claim</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/refunds.htm">Requesting a Refund</a></li>      

          <div class="desktop-only mailship-addition"><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/go-now.htm"><img src="https://www.usps.com/ship/go-now.png" alt="Mail and Ship image with call to action." /></a></div>

         </ul>

       </div>

     </li>

     <li  class="menuheader" >

         <a tabindex="-1" name="navtrackmanage" id="navtrackmanage" href="#navpostalstore" class="hidden-skip">Skip Receive Links</a>

       <a aria-expanded="false" class="menuitem" role="menuitem" tabindex="0" aria-haspopup="true" href="https://www.usps.com/manage/">Receive</a>

       <div>

         <ul role="menu" aria-hidden="true" class="tools">

           <h3>Tools</h3>

           <li class="tool-track"><a role="menuitem" tabindex="-1" href="https://tools.usps.com/go/TrackConfirmAction_input">Tracking</a></li>

           <li class="tool-informed"><a role="menuitem" tabindex="-1" href="https://informeddelivery.usps.com">Informed Delivery</a></li>

           <li class="tool-intercept"><a role="menuitem" tabindex="-1" href="https://retail-pi.usps.com/retailpi/actions/index.action">Intercept a Package</a></li>

           <li class="tool-redelivery"><a role="menuitem" tabindex="-1" href="https://tools.usps.com/redelivery.htm">Schedule a Redelivery</a></li>

           <li class="tool-hold"><a role="menuitem" tabindex="-1" href="https://holdmail.usps.com/holdmail/">Hold Mail</a></li>

           <li class="tool-change"><a role="menuitem" tabindex="-1" href="https://moversguide.usps.com/?referral=MG80">Change of Address</a></li>

           <li class="tool-pobol"><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/po-boxes.htm">Rent or Renew PO Box</a></li>

         </ul>

         <ul role="menu" aria-hidden="true">

           <h3>Learn About</h3>            

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/">Managing Mail</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://informeddelivery.usps.com/">Informed Delivery</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/forward.htm">Forwarding Mail</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/package-intercept.htm">Redirecting a Package</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.uspssmartpackagelockers.com/package-recipients">USPS Smart Lockers</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/po-boxes.htm">PO Boxes</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/mailboxes.htm">Mailbox Guidelines</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/mail-for-deceased.htm">Mail for the Deceased</a></li>

           <div class="desktop-only manage-addition"><a role="menuitem" tabindex="-1" href="https://www.usps.com/manage/go-now.htm"><img src="https://www.usps.com/manage/go-now.png" alt="Manage image with call to action." /></a></div>

         </ul>

       </div>

     </li>

     <li  class="menuheader" >

         <a tabindex="-1" name="navpostalstore" id="navpostalstore" href="#navbusiness" class="hidden-skip">Skip Shop Links</a>

       <a aria-expanded="false" class="menuitem"  role="menuitem" tabindex="0" aria-haspopup="true" href="https://store.usps.com/store">Shop</a>

       <div class="repos">

         <ul role="menu" aria-hidden="true" class="tools">

           <h3>Shop</h3>

           

 

           <li class="tool-stamps"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/stamps">Stamps</a></li>

           <li class="tool-supplies"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/shipping-supplies">Shipping Supplies</a></li>

           <li class="tool-cards"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/cards-envelopes">Cards &amp; Envelopes</a></li>

           <li class="tool-pse"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/pse/">Personalized Stamped Envelopes</a></li>

           <li class="tool-coll"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/collectors">Collectors</a></li>

           <li class="tool-gifts"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/gifts">Gifts</a></li>

           <li class="tool-business"><a role="menuitem" tabindex="-1" href="https://store.usps.com/store/business-supplies">Business Supplies</a></li>

         </ul>

 

         <ul role="menu" aria-hidden="true">

           <h3>Learn About</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/shop/money-orders.htm">Money Orders</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/shop/returns-exchanges.htm">Returns &amp; Exchanges</a></li>

           <div class="desktop-only shop-addition">

             <a role="menuitem" tabindex="-1" href="https://www.usps.com/store/go-now.htm"><img src="https://www.usps.com/store/go-now.png" alt="Store image with call to action."/></a>

           </div>

         </ul>

 

       </div>

     </li>

     <li  class="menuheader" >

         <a tabindex="-1" name="navbusiness" id="navbusiness" href="#navinternational" class="hidden-skip">Skip Business Links</a>

       <a aria-expanded="false" class="menuitem" tabindex="0" aria-haspopup="true" role="menuitem" href="https://www.usps.com/business/">Business</a>

       <div class="repos">

         <ul role="menu" aria-hidden="true" class="tools">

           <h3>Tools</h3>

           <li class="tool-calc"><a role="menuitem" tabindex="-1" href="https://postcalc.usps.com/business">Calculate a Business Price</a></li>

           <li class="tool-eddm"><a role="menuitem" tabindex="-1" href="https://eddm.usps.com/eddm/customer/routeSearch.action">Every Door Direct Mail</a></li>

           <div class="desktop-only business-addition">

             <a role="menuitem" tabindex="-1" href="https://www.usps.com/business/go-now.htm"><img src="https://www.usps.com/business/go-now.png" alt="Business image with call to action."/></a>

           </div>

         </ul>

 

         <ul role="menu" aria-hidden="true">

           <h3>Learn About</h3>

           

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/business-shipping.htm">Business Shipping</a></li>

           <ul>

             <li><a role="menuitem" tabindex="-1" href="https://www.uspssmartpackagelockers.com/business">Ship to USPS Smart Locker</a></li>        

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/shipping-consolidators.htm">Shipping Consolidators</a></li>          

           </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/advertise-with-mail.htm">Advertising with Mail</a></li>

           <ul>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/every-door-direct-mail.htm">Using EDDM</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/vendors.htm">Mailing &amp; Printing Services</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/customized-direct-mail.htm">Customized Direct Mail</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/political-mail.htm">Political Mail</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/promotions-incentives.htm">Promotions &amp; Incentives</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/informed-delivery.htm">Informed Delivery Marketing</a></li>

           </ul>

         </ul>

         <ul role="menu" aria-hidden="true">

           <h3 class="desktop-only">&nbsp;</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/connect/">USPS Connect</a></li>

           <ul>          

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/connect/ecommerce.htm">eCommerce</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/connect/local.htm">Local</a></li>

           </ul>            

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/postage-options.htm">Postage Options</a></li>

           <ul>          

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/verify-postage.htm">Verifying Postage</a></li>

           </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/return-services.htm">Returns Services</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/label-broker.htm">Label Broker for Business</a></li>      

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/international-shipping.htm">International Business Shipping</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/manage-mail.htm">Managing Business Mail</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/web-tools-apis/">USPS &amp; Web Tools APIs</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/business/prices.htm">Prices</a></li>

         </ul>

 

       </div>

     </li>

     <li  class="menuheader" >

       <a tabindex="-1" name="navinternational" id="navinternational" href="#navhelp" class="hidden-skip">Skip International Links</a>

       <a class="menuitem" tabindex="0" aria-expanded="false" aria-haspopup="true" role="menuitem"  href="https://www.usps.com/international/">International</a>

       <div class="repos">

         <ul role="menu" aria-hidden="true" class="tools">

           <h3>Tools</h3>

           

           <li class="tool-calc"><a role="menuitem" tabindex="-1" href="https://postcalc.usps.com/?country=10440">Calculate International Prices</a></li>

           <li class="tool-international-labels"><a role="menuitem" tabindex="-1" href="https://cns.usps.com/">Print International Labels</a></li>

           <li class="tool-international-forms"><a role="menuitem" tabindex="-1" href="https://cfo.usps.com/cfo-web/labelInformation.html">Print Customs Forms</a></li>

           <div class="desktop-only international-addition">

             <a role="menuitem" tabindex="-1" href="https://www.usps.com/international/go-now.htm"><img src="https://www.usps.com/international/go-now.png" alt="International image with call to action" /></a>

           </div>

         </ul>

 

         <ul role="menu" aria-hidden="true">

           <h3>Learn About</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/">International Sending</a></li>              

           <ul>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/letters.htm">How to Send a Letter Internationally</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/preparing-international-shipments.htm">How to Send a Package Internationally</a></li>            

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/shipping-restrictions.htm">International Shipping Restrictions</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/international-how-to.htm">Shipping Internationally Online</a></li>      

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/insurance-extra-services.htm">International Insurance &amp; Extra Services</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/customs-forms.htm">Completing Customs Forms</a></li>

           </ul>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/ship/apo-fpo-dpo.htm?pov=international">Military &amp; Diplomatic Mail</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/money-transfers.htm">Sending Money Abroad</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/passports.htm">Passports</a></li>

         </ul>

         

         <ul role="menu" aria-hidden="true">

           <h3 class="desktop-only">&nbsp;</h3>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/mail-shipping-services.htm">Comparing International Shipping Services</a></li>  

           <ul>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/gxg.htm">Global Express Guaranteed</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/priority-mail-express-international.htm">Priority Mail Express International</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/priority-mail-international.htm">Priority Mail International</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/first-class-package-international-service.htm">First-Class Package International Service</a></li>

             <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/international/first-class-mail-international.htm">First-Class Mail International</a></li>        

             

           </ul>    

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/international-claims.htm">Filing an International Claim</a></li>

           <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/international-refunds.htm">Requesting an International Refund</a></li>      

         </ul>

         

       </div>

     </li>

     <li  class="menuheader" >

         <a tabindex="-1" name="navhelp" id="navhelp" href="#navsearch" class="hidden-skip">Skip Help Links</a>

       <a aria-expanded="false" class="menuitem" tabindex="0" aria-haspopup="true"  role="menuitem" href="https://faq.usps.com/s/">Help</a>

           <div class="repos">

             <ul role="menu" aria-hidden="true">

               <li><a role="menuitem" tabindex="-1" href="https://faq.usps.com/s/">FAQs</a></li>

               <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/missing-mail.htm">Finding Missing Mail</a></li>

               <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/claims.htm">Filing a Claim</a></li>

               <li><a role="menuitem" tabindex="-1" href="https://www.usps.com/help/refunds.htm">Requesting a Refund</a></li>

             </ul>

           </div>

     </li>

     <li class="nav-search menuheader">

         <a tabindex="-1" name="navsearch" id="navsearch" href="#endnav" class="hidden-skip">Skip Search</a>

       <a aria-expanded="false" class="menuitem" tabindex="0" aria-haspopup="true" role="menuitem" href="https://www.usps.com/search/results.htm?keyword=Contact%20Us">Search USPS.com</a>

       </li>

       <!-- END Search -->

       </div>

     </li>

 

   </ul>

 </nav>

 

   <a name="endnav" id="endnav" href="#" class="hidden-skip">&nbsp;</a>

</div></div>

`;

 

export const NewGlobalHeader = () => {

    return <div dangerouslySetInnerHTML={{ __html: headerHtml }} />;

};