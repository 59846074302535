import { Theme } from "@informed-innovations/components";

export const CopyIcon = ({ fill, ...props }) => {
  return (
    <svg
      {...props}
      id="Icon-Duplicate"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="25"
      viewBox="0 0 21.591 25"
    >
      <title>Copy Icon</title>
      <path
        id="Icon-Duplicate-2"
        data-name="Icon-Duplicate"
        d="M19.319,4.546H6.818A2.28,2.28,0,0,0,4.546,6.818v15.91A2.28,2.28,0,0,0,6.818,25h12.5a2.28,2.28,0,0,0,2.272-2.272V6.818a2.28,2.28,0,0,0-2.272-2.272m0,18.182H6.818V6.818h12.5ZM15.91,2.272H2.272v15.91H0V2.272A2.281,2.281,0,0,1,2.272,0H15.91Z"
        fill={fill ? fill : Theme.colors.blue}
      />
    </svg>
  );
};
