import { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme, UspsCarrat } from "@informed-innovations/components";
import { RowsDropdown, Option } from "../shared/RowsDropdown";
import PropTypes from "prop-types";

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: right;
  padding: 20px 5px;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const PaginationPageButton = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  color: ${Theme.colors.blue};
  background-color: ${Theme.colors.white};
  display: inline-block;
  ${(props) => props.currentPage && `color: ${Theme.colors.white}; background-color: ${Theme.colors.blue};`};
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    color: ${Theme.colors.blue};
    background-color: ${Theme.colors.background_hover_gray};
  }
`;

const PaginationBackButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const PaginationNextButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const BackCarrat = styled(UspsCarrat)`
  transform: scaleX(-1);
`;

const Footer = styled.div`
  @media screen and (max-width: 960px) {
    height: 84px;
    background-color: ${Theme.colors.gray[100]};
    position: absolute;
    z-index: 1;
    border-top: 1px solid ${Theme.colors.gray[300]};
    border-bottom: 1px solid ${Theme.colors.gray[300]};
    display: flex;
    right: 0;
    left: 0;
  }
`;

const FooterPagination = styled.div`
  @media screen and (max-width: 960px) {
    background-color: ${Theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${Theme.colors.gray[300]};
    width: 100%;
    button {
      appearance: none;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
`;

const FooterPaginationInner = styled.div`
  background-color: ${Theme.colors.white};
  display: flex;

  svg {
    width: 15px;
    height: 30px;
  }
`;

const FooterPaginationLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  color: ${Theme.colors.blue};
`;

const DropdownContainer = styled.div`
  @media screen and (max-width: 960px) {
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const MailPagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  currentPage,
  setCurrentPage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
}) => {
  const getPageCount = (count, rowsPerPage) => Math.ceil(count / rowsPerPage);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const pageRange = (count, rowsPerPage, currentPage) => {
    let pageCount = getPageCount(count, rowsPerPage);

    let start = currentPage - 2;
    let end = currentPage + 2;

    if (end > pageCount) {
      start -= end - pageCount;
      end = pageCount;
    }

    if (start <= 0) {
      end += (start - 1) * -1;
      start = 1;
    }

    end = end > pageCount ? pageCount : end;

    let pages = [];

    for (let loop = start; loop <= end; loop++) {
      pages.push(loop);
    }
    return pages;
  };

  const handleBackButton = () => {
    page === 0 ? onChangePage(1) : onChangePage(page);
  };

  const handlePage = (page) => {
    onChangePage(page);
  };

  const handleNextButton = () => {
    onChangePage(page + 2);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <PaginationContainer>
      <DropdownContainer>
        <RowsDropdown
          onSelect={(amount) => {
            onChangeRowsPerPage(Number(amount));
            setCurrentPage(1);
          }}
        >
          {paginationRowsPerPageOptions.map((num) => {
            return (
              <Option key={num} value={num}>
                {num}
              </Option>
            );
          })}
        </RowsDropdown>
      </DropdownContainer>
      <Footer>
        <FooterPagination>
          {isDesktop ? (
            <>
              <PaginationBackButton
                disabled={page === 0}
                aria-disabled={page === 0}
                aria-label="previous page"
                onClick={handleBackButton}
              >
                <BackCarrat />
              </PaginationBackButton>
              {pageRange(count, rowsPerPage, currentPage).map((page) => (
                <PaginationPageButton
                  key={page}
                  currentPage={currentPage === page}
                  onClick={() => {
                    handlePage(page);
                    scrollToTop();
                  }}
                >
                  {page}
                </PaginationPageButton>
              ))}
              <PaginationNextButton
                disabled={page >= getPageCount(count, rowsPerPage) - 1}
                aria-disabled={page >= getPageCount(count, rowsPerPage) - 1}
                aria-label="next page"
                onClick={handleNextButton}
              >
                <UspsCarrat />
              </PaginationNextButton>
            </>
          ) : (
            <FooterPaginationInner>
              <button onClick={() => handleBackButton()} disabled={currentPage === 1}>
                <BackCarrat />
              </button>
              <FooterPaginationLabel>
                <strong>
                  Page&nbsp;
                  {currentPage}
                  &nbsp;of&nbsp;
                  {getPageCount(count, rowsPerPage)}
                </strong>
              </FooterPaginationLabel>
              <button onClick={() => handleNextButton()} disabled={currentPage === getPageCount(count, rowsPerPage)}>
                <UspsCarrat />
              </button>
            </FooterPaginationInner>
          )}
        </FooterPagination>
      </Footer>
    </PaginationContainer>
  );
};

MailPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  paginationRowsPerPageOptions: PropTypes.array
}