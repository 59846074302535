import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { hasRole } from "../services/apiHelpers";
import { NoAccess } from "./NoAccess";
import { pingApi } from "../services/pingApi";
import { SESSION_KEY } from "../config/constants";
import PropTypes from "prop-types";

export const AuthSecured = ({ requiredRole, ...props }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const broadcastChannel = new BroadcastChannel("auth_channel");

  const [getPing, { /*data: ping, isLoading: pingLoading,*/ error: pingError }] = pingApi.endpoints.getPing.useLazyQuery();

  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    let tickCount = 0;

    const intervalId = setInterval(() => {
      const millis = localStorage.getItem(SESSION_KEY.MILLIS);
      if (millis !== null) {
        tickCount++;
        if (Date.now() - millis >= 15 * 60 * 1000) {
          navigate("/logout");
        } else if (tickCount % 30 === 0) {
          const remainingMinutes = Math.floor((15 * 60 * 1000 - (Date.now() - millis)) / 60000);
          console.log(`${remainingMinutes} minutes remaining`);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data.type === "LOGOUT") {
        navigate("/logout?nb=1");
      }
    };

    broadcastChannel.addEventListener("message", messageHandler);

    return () => {
      broadcastChannel.removeEventListener("message", messageHandler);
      broadcastChannel.close();
    };
  }, [broadcastChannel, navigate]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("Tab became visible");
        getPing();
      }
    };

    const handleWindowFocus = () => {
      console.log("Window gained focus");
      getPing();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [getPing]);

  useEffect(() => {
    if (pingError && pingError.status === 403) {
      navigate("/logout");
    }
  }, [pingError, navigate]);

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated && !auth.error) {
      auth.signinRedirect({ state: { redirectUrl: window.location.pathname + window.location.search } });
    }
  }, [auth]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      setIsAuthorized(true);
    }
  }, [auth.isAuthenticated]);

  if (auth.isLoading || !isAuthorized) {
    return null;
  }

  if (auth.error) {
    return <div>{auth.error.error}</div>;
  }

  if (isAuthorized) {
    if (!requiredRole || (requiredRole && hasRole(requiredRole))) {
      return props.children || null;
    } else {
      return <NoAccess />;
    }
  }

  return null;
};


AuthSecured.propTypes = {
  requiredRole: PropTypes.string,
  children: PropTypes.node,
}