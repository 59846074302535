import styled from "styled-components";
import { useState } from "react";
import { RowsDropdown, Option } from "../shared/RowsDropdown";
import { Theme, UspsCarrat, Button } from "@informed-innovations/components";
import { RefreshIcon } from "../../common/components/icons/RefreshIcon";
import PropTypes from 'prop-types';

const PaginationContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: right;
  padding: 20px 0;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: right;
  padding: 20px 0;
`;

const PaginationPageButton = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  color: ${Theme.colors.blue};
  background-color: ${Theme.colors.white};
  display: inline-block;
  ${(props) => props.currentPage && `color: ${Theme.colors.white}; background-color: ${Theme.colors.blue};`};
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    color: ${Theme.colors.blue};
    background-color: ${Theme.colors.background_hover_gray};
  }
`;

const PaginationBackButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const PaginationNextButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const BackCarrat = styled(UspsCarrat)`
  transform: scaleX(-1);
`;

const RefreshContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 20%;
  margin-right: auto;
  font-size: 12px;
  display: block;
  text-align: center;
  max-width: 275px;
  p {
    margin: 0;
  }
`;

const RefreshButton = styled(Button)`
  color: ${Theme.colors.blue};
  svg {
    margin-bottom: 5px;
    margin-right: 4px;
  }
`;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PackagesPagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  currentPage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
}) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const getPageCount = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage);

  const pageRange = (rowCount, rowsPerPage, currentPage) => {
    let pageCount = getPageCount(rowCount, rowsPerPage);

    let start = currentPage - 2;
    let end = currentPage + 2;

    if (end > pageCount) {
      start -= end - pageCount;
      end = pageCount;
    }

    if (start <= 0) {
      end += (start - 1) * -1;
      start = 1;
    }

    end = end > pageCount ? pageCount : end;

    let pages = [];

    for (let loop = start; loop <= end; loop++) {
      pages.push(loop);
    }

    return pages;
  };

  const handleBackButton = () => {
    page === 0 ? onChangePage(1) : onChangePage(page);
    scrollToTop();
  };

  const handlePage = (page) => {
    onChangePage(page);
    scrollToTop();
  };

  const handleNextButton = () => {
    onChangePage(page + 2);
    scrollToTop();
  };

  const [refreshDate, setRefreshDate] = useState([
    new Date().getMonth(),
    new Date().getDate(),
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  ]);

  const handleRefresh = () => {
    setRefreshDate([
      new Date().getMonth(),
      new Date().getDate(),
      new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    ]);
  };

  return (
    <PaginationContainer>
      <PaginationWrapper>
        <div>
          <RowsDropdown
            onSelect={(amount) => {
              onChangeRowsPerPage(amount);
            }}
          >
            {paginationRowsPerPageOptions.map((num) => {
              return (
                <Option key={num} value={num}>
                  {num}
                </Option>
              );
            })}
          </RowsDropdown>
        </div>
        <RefreshContainer>
          <p>
            Last Updated {monthNames[refreshDate[0]]} {refreshDate[1]}, {refreshDate[2].toLowerCase()}{" "}
          </p>
          <RefreshButton text onClick={() => handleRefresh()}>
            <RefreshIcon fill={Theme.colors.blue} />
            Refresh
          </RefreshButton>
        </RefreshContainer>
        <div style={{ marginTop: "10px" }}>
          <PaginationBackButton
            disabled={page === 0}
            aria-disabled={page === 0}
            aria-label="previous page"
            onClick={handleBackButton}
          >
            <BackCarrat />
          </PaginationBackButton>
          {pageRange(count, rowsPerPage, currentPage).map((page, i) => (
            <PaginationPageButton
              key={`${page}-${i}`}
              currentPage={currentPage === page}
              onClick={() => handlePage(page)}
            >
              {page}
            </PaginationPageButton>
          ))}
          <PaginationNextButton
            disabled={page >= getPageCount(count, rowsPerPage) - 1}
            aria-disabled={page >= getPageCount(count, rowsPerPage) - 1}
            aria-label="next page"
            onClick={handleNextButton}
          >
            <UspsCarrat />
          </PaginationNextButton>
        </div>
      </PaginationWrapper>
    </PaginationContainer>
  );
};

PackagesPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
  paginationRowsPerPageOptions: PropTypes.array,
}