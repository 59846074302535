export const IncomingPackagesIcon = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36.842" viewBox="0 0 35 36.842" {...props}>
      <path
        id="Path_3913"
        data-name="Path 3913"
        d="M34.509,7.11,28.5.6A1.853,1.853,0,0,0,27.141,0H7.859A1.853,1.853,0,0,0,6.5.6L.491,7.11A1.858,1.858,0,0,0,0,8.369V34.987a1.854,1.854,0,0,0,1.853,1.856H33.147A1.854,1.854,0,0,0,35,34.987V8.369A1.858,1.858,0,0,0,34.509,7.11ZM31.292,33.132H3.708V9.374h8.966v4.5a.985.985,0,0,0,.984.986h7.356A.985.985,0,0,0,22,13.876v-4.5h9.294V33.132Zm-17.043,0M26.33,3.711l3.4,3.69H21.788l-.9-3.69Zm-10.564.174h3.14L19.76,7.4H14.911ZM14.642,9.374h5.386v3.517H14.642ZM8.67,3.711h5.111l-.9,3.69H5.267Z"
        fill={fill ? fill : "3573b1"}
      />
    </svg>
  );
};
