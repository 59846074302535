export const GreenCheckIcon = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24.681" viewBox="0 0 30 24.681" {...props}>
      <g id="Icon-Link" transform="translate(-5622.5 20412.285)">
        <g id="Icon-Check-Approved" transform="translate(5622.5 -20412.285)">
          <path
            id="Path_5749"
            data-name="Path 5749"
            d="M436.526,315.112a10.119,10.119,0,0,1-11.626,8.259,10,10,0,0,1-8.388-11.45,10.193,10.193,0,0,1,17.213-5.426l1.888-1.549a12.668,12.668,0,0,0-17.719-.323,12.21,12.21,0,0,0-.327,17.45,12.667,12.667,0,0,0,17.718.322,12.244,12.244,0,0,0,3.836-8.9c0-.165,0-.33-.01-.506Z"
            transform="translate(-414.059 -301.168)"
            fill={fill ? fill : "#218748"}
          />
          <path
            id="Path_5750"
            data-name="Path 5750"
            d="M441.113,303.125l-.008-.01v0a2.237,2.237,0,0,0-3.109-.264L425.2,313.364l-3.763-3.691a2.244,2.244,0,0,0-3.123,0,2.135,2.135,0,0,0-.012,3.05l.012.01,6.621,6.49,15.892-13.06A2.136,2.136,0,0,0,441.113,303.125Z"
            transform="translate(-411.62 -300.402)"
            fill={fill ? fill : "#218748"}
          />
        </g>
      </g>
    </svg>
  );
};
