import { CloseIcon, Theme } from "@informed-innovations/components";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  gap: 10px;
  background-color: ${Theme.colors.background_hover_gray};
  color: ${Theme.colors.blue};
  font-size: 12px;
  padding: 5px 10px;
  margin: 20px 0;
  align-items: center;
  @media screen and (max-width: 960px) {
    margin: 5px 0;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  svg {
    width: 10px;
    height: 10px;
  }
`;

export const FilterListItem = ({ filter, onRemove }) => {
  return (
    <Container>
      {filter}
      <CloseButton
        title={`Remove filter:${filter}`}
        onClick={() => {
          if (onRemove) {
            onRemove();
          }
        }}
      >
        <CloseIcon />
      </CloseButton>
    </Container>
  );
};

FilterListItem.propTypes = {
  filter: PropTypes.array,
  onRemove: PropTypes.func,
}