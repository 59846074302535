import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "./Loading";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const AuthCallbackDisplay = () => {
  const query = useQuery();
  const navigate = useNavigate();

  if (query.has("error")) {
    navigate("/");
    return null;
  }
  return <Loading />;
};