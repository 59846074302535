import { Theme, UspsCarrat } from "@informed-innovations/components";
import styled from "styled-components";
import { RowsDropdown, Option } from "../shared/RowsDropdown";
import PropTypes from "prop-types";

const PaginationContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: right;
  padding: 20px 0;
`;

const BackCarrat = styled(UspsCarrat)`
  transform: scaleX(-1);
`;

const Footer = styled.div`
  @media screen and (max-width: 960px) {
    height: 84px;
    background-color: ${Theme.colors.gray[100]};
    position: absolute;
    z-index: 1;
    border-top: 1px solid ${Theme.colors.gray[300]};
    border-bottom: 1px solid ${Theme.colors.gray[300]};
    display: flex;
    right: 0;
    left: 0;
    bottom: 0px;
  }
`;

const FooterPagination = styled.div`
  @media screen and (max-width: 960px) {
    background-color: ${Theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${Theme.colors.gray[300]};
    width: 100%;
    button {
      appearance: none;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
`;

const FooterPaginationInner = styled.div`
  background-color: ${Theme.colors.white};
  display: flex;

  svg {
    width: 15px;
    height: 30px;
  }
`;

const FooterPaginationLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  color: ${Theme.colors.blue};
`;

const DropdownContainer = styled.div`
  @media screen and (max-width: 960px) {
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
`;
export const PackagesSecondaryPagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  currentPage,
  setCurrentPage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
  isDesktop,
}) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const getPageCount = (count, rowsPerPage) => Math.ceil(count / rowsPerPage);

  const handleBackButton = () => {
    page === 0 ? onChangePage(1) : onChangePage(page);
    scrollToTop();
  };

  const handleNextButton = () => {
    onChangePage(page + 2);
    scrollToTop();
  };
  return (
    <PaginationContainer>
      <DropdownContainer>
        {getPageCount(count, rowsPerPage) > 0 && (
          <RowsDropdown
            onSelect={(amount) => {
              onChangeRowsPerPage(Number(amount));
              setCurrentPage(1);
            }}
          >
            {paginationRowsPerPageOptions.map((num) => {
              return (
                <Option key={num} value={num}>
                  {num}
                </Option>
              );
            })}
          </RowsDropdown>
        )}
      </DropdownContainer>
      {!isDesktop && getPageCount(count, rowsPerPage) > 0 && (
        <Footer>
          <FooterPagination>
            <FooterPaginationInner>
              <button onClick={() => handleBackButton()} disabled={currentPage === 1}>
                <BackCarrat />
              </button>
              <FooterPaginationLabel>
                <strong>
                  Page&nbsp;{currentPage}&nbsp;of&nbsp;
                  {getPageCount(count, rowsPerPage)}
                </strong>
              </FooterPaginationLabel>
              <button onClick={() => handleNextButton()} disabled={currentPage === getPageCount(count, rowsPerPage)}>
                <UspsCarrat />
              </button>
            </FooterPaginationInner>
          </FooterPagination>
        </Footer>
      )}
    </PaginationContainer>
  );
};

PackagesSecondaryPagination.propTypes = {
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  paginationRowsPerPageOptions: PropTypes.array,
  isDesktop: PropTypes.bool,
};
