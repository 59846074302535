import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Theme } from "@informed-innovations/components";
import { getAddress, getFirstName } from "../../services/apiHelpers";
import PropTypes from "prop-types";

const Header = styled.div`
  width: 100%;
  text-align: left;
  letter-spacing: 0px;
  z-index: 2;
  top: 0;
  background: white;

  p {
    color: ${Theme.colors.black};
    margin-bottom: 0px;
  }
  h1 {
    margin-bottom: 25px;
  }

  @media screen and (max-width: 960px) {
    p {
      margin-bottom: 8px;
    }
  }
`;

const HeaderContent = styled.div`
  margin: 0 auto;
  max-width: 1230px;
  padding: 40px 0px;
  width: 100%;

  @media screen and (max-width: 960px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const HeaderContentInner = styled.div`
  b {
    color: ${Theme.colors.blue};
  }
  @media screen and (max-width: 960px) {
    p {
      text-align: left;
    }
    h4 {
      margin-bottom: 30px;
    }
    div {
      margin: 0 20px;
    }
  }
`;

const HeaderAddressDesktop = styled.div`
  width: 100%;
  text-align: left;
  letter-spacing: 0px;
  background: ${Theme.colors.gray[100]};
  @media screen and (max-width: 1305px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const HeaderAddressMobile = styled.div`
  display: none;
  width: 100%;
  text-align: left;
  letter-spacing: 0px;
  background: ${Theme.colors.gray[100]};
  padding: 30px;

  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const HeaderAddress = styled.div`
  display: flex;
  color: ${Theme.colors.black};
  margin: 0 auto;
  max-width: 1230px;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    display: block;
    position: relative;
  }
`;

const LeftSpan = styled.span`
  font-size: 14px;
  padding: 20px 0;
  b {
    color: ${Theme.colors.blue};
    font-size: 15px;
  }
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const LeftDiv = styled.div`
  display: none;
  padding-top: 20px;
  b {
    color: ${Theme.colors.blue};
    font-size: 20px;
  }
  @media screen and (max-width: 960px) {
    display: block;
  }
`;

const RightSpan = styled.span`
  padding-top: 15px;
  h2 {
    margin-bottom: 0;
    font-weight: normal;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const RightDiv = styled.div`
  display: none;
  h2 {
    font-weight: normal;
  }
  @media screen and (max-width: 960px) {
    display: block;
    right: 0;
    text-align: right;
    top: 20px;
  }
`;

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const WelcomeHeader = ({ greeting }) => {
  const currentDate = [
    new Date().getMonth(),
    new Date().getDate(),
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
  ];
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 960);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const currentMonth = monthNames[currentDate[0]].slice(0, 3).toUpperCase();
  const currentDay = currentDate[1];

  return (
    <>
      <Header>
        <HeaderContent>
          <HeaderContentInner>
            <h4>Welcome to Informed Delivery&reg; Business Dashboard</h4>
            <div>
              {isDesktop ? (
                <span>
                  <b>Hi, {getFirstName()}. </b>
                  {greeting}
                </span>
              ) : (
                <span>
                  <p>
                    <b>Hi, {getFirstName()}. </b>
                  </p>
                  <p>{greeting}</p>
                </span>
              )}
            </div>
          </HeaderContentInner>
        </HeaderContent>
      </Header>
      <HeaderAddressDesktop>
        <HeaderAddress>
          <LeftSpan>
            Address: <b>{getAddress()}</b>
            &nbsp;
          </LeftSpan>
          <RightSpan>
            <h2>
              <b>{currentMonth}</b> {currentDay}
            </h2>
          </RightSpan>
        </HeaderAddress>
      </HeaderAddressDesktop>
      <HeaderAddressMobile>
        <b>Address:</b>
        <HeaderAddress>
          <LeftDiv>
            <b>{getAddress()}</b>
          </LeftDiv>
          <RightDiv>
            <h2>
              <b>{currentMonth}</b> {currentDay}
            </h2>
          </RightDiv>
        </HeaderAddress>
      </HeaderAddressMobile>
    </>
  );
};

WelcomeHeader.propTypes = {
  greeting: PropTypes.string,
};
